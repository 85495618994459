import React, { useEffect, useState, ReactElement  } from 'react'

import { Worker, Viewer, PageChangeEvent, Icon } from '@react-pdf-viewer/core';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { failureToaster, successToaster } from '../utils/Toaster';
import AuthenticationService from '../authentication/AuthenticationService';
import { API_URL } from '../utils/applicationConstants';
import ProjectService from '../services/project/ProjectService';
import {  FormControlLabel, Switch, Typography } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import { List, ExpandMore, ChevronRight } from '@mui/icons-material';
import { TreeItem } from '@mui/lab';
import {AddCircleOutlined} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import {Button, Box, Modal, TextField, Checkbox,MenuItem} from '@mui/material';
import $ from 'jquery'; 
import { toast } from'react-toastify';

const PdfViewer = () => {

    const [pageNumber, setPageNumber] = useState(1);
    const [documentId, setDocumentId] = useState('');   
    const [phrases, setPhrases] = useState([])
    const [highlightedSentences, setHighlightedSentences] = useState([])
    const [highlighted, setHighlighted] = useState(true);
    const [addModalOpen, setAddModalOpen] = useState(false);

    const [updateModalOpen, setUpdateModalOpen] = useState([]);
    const [conceptId, setConceptId] = useState([]);
    const [concepts, setConcepts] = useState([]);
    const [conceptName, setConceptName] = useState('');
    const [selectedConcepts, setSelectedConcepts] = useState([]);
    const [projectId, setProjectId] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [conceptData, setConceptData] = useState([]);
    const [name, setName] = useState([]);
    const [description, setDescription] = useState([]);
    const [id, setId] = useState([]);
    const [isConceptExpanded, setIsConceptExpanded] = useState([]);
    const [selectedConceptIds, setSelectedConceptIds] = useState([]);

    const [searchText, setSearchText] = useState([]);
    const [searchText2, setSearchText2] = useState([]);
    const [searchText3, setSearchText3] = useState([]);
    const [selectSearchText, setSelectSearchText] = useState([]);
    const [selectPhraseText, setSelectPhraseText] = useState([]);

    let tmpPhrases = [];
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const documentid = urlParams.get('documentId');
        setDocumentId(documentid);

        setPageNumber(urlParams.get('pageNumber'));
        let highlighted = highlightedSentences;
        const searchText = urlParams.get('searchText');
        if(searchText != undefined){
            highlighted.push(searchText)
            setSearchText(searchText);
        }
           

        const searchText2 = urlParams.get('searchText2');
        if(searchText2 != undefined){
            highlighted.push(searchText2)
            setSearchText2(searchText2);
        }    

        const searchText3 = urlParams.get('searchText3');
        if(searchText3 != undefined){
            highlighted.push(searchText3);
            setSearchText3(searchText3);
        }     
        const projectId = urlParams.get('projectId');
       if(projectId != null && projectId != undefined){
        setProjectId(projectId);
       }
        
        setHighlightedSentences(highlighted);


        ProjectService.getPhraseByDocumentId(documentid)
                        .then(result => {
                            setPhrases(result.data);
                            tmpPhrases = result.data;
                            let tempConcepts = []

                            result.data.map(phrase => {
                                let filteredConcepts = tempConcepts.filter(c => c.id == phrase.concept.id);
                                if(filteredConcepts.length <= 0){
                                    let concept = phrase.concept;
                                    concept.phrases = [];
                                    concept.phrases.push(phrase);
                                    tempConcepts.push(concept)   
                                }else{
                                    filteredConcepts[0].phrases.push(phrase);
                                }

                                if(!highlighted.includes(phrase.phraseText)){
                                    highlighted.push({keyword:phrase.phraseText,
                                        matchCase:false,
                                        wholeWords:false
                                    });
                                    setHighlightedSentences(highlighted);
                                }
                            });
                            setConcepts(tempConcepts);
                        })
                        .catch((error) =>{
                            //failureToaster(error.response.data.message)
                        } );
                        
                        fetchConcepts(projectId);          
    },[]);

   const fetchConcepts = (projectId) =>{
        ProjectService.getConceptsByProjectId(projectId)
                        .then(response => {
                            let conceptData = response.data;
                            if(undefined == conceptData || null == conceptData  || conceptData.length == 0){
                                setConceptData([]);
                            }else{
                                setConceptData(conceptData);
                            }
                            
                        })
                        .catch(error => { 
                            if(error.response.status == 403){
                                
                                AuthenticationService.handleTokenExpired();
                                return;
                            }
                            failureToaster(error.message)
                        });
    }
    const renderToolbar = (Toolbar)=> (
        <>
        <Toolbar />
        <div>
            <Tooltip title='Add Concept'>
                <AddCircleOutlined sx={{cursor: 'pointer' , color: '#888'}}  onClick={() => {openAddModal()}} />
           </Tooltip>
        </div>&nbsp;
        <div >  
        <Tooltip title='Highlight'>                          
          <Switch checked={highlighted} sx={{cursor: 'pointer', marginTop:'7%'}}   onChange={(e) => {toggleHighlight(e.target.checked)}}  />
         
        </Tooltip>    
        </div>     
       
        </>
        );

    const ConceptList = () => {
        return (
            <>
                {concepts?.map((concept, index) => 
                    (
                        
                        <TreeItem key={index} nodeId={`${index}`} label={concept.conceptName}>
                            {concept.phrases.map((phrase, i) => (
                                <TreeItem key={i} nodeId={`${phrase.id}-${i}`} label={`${phrase.searchText} (Page ${phrase.pageNumber})`} >
                                    <TreeItem  onClick={() => {jumpToPage(phrase.pageNumber-1);}} nodeId={`${phrase.id}-${i}-${i}`} label={`${phrase.phraseText}`} />    
                                </TreeItem>
                            ))}
                        </TreeItem>
                    )
                )}
            </>
        )
    }
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: (defaultTabs) => 
        defaultTabs.concat({
            content: (
                <div>
                    <Typography sx={{ml: '13px', fontWeight: 'bold'}} color='primary' variant="button" display="block" gutterBottom>
                     List of concepts
                    </Typography>
                    <TreeView   
                        aria-label="file system navigator" 
                        defaultCollapseIcon={<ExpandMore />}  
                        defaultExpandIcon={<ChevronRight />}
                        sx={{ flexGrow: 1,overflowY: 'auto' }}>
                        
                        <ConceptList />
                    </TreeView>


                </div>),
            icon: (
                <List sx={{color: '#888'}} />
            ),
            title: 'Concepts List',
        },
        // {
        //     content: (
        //         <div>
        //             <button onClick={() => {openAddModal()}} />
                    
        //         </div>),
        //     icon: (
        //         <AddCircleOutlined sx={{color: '#888'}}   onClick={() => {openAddModal()}}  />
        //     ),
        //     title: 'Add Concept',
        // },
        ),
        toolbarPlugin: {
            searchPlugin: {
                
                keyword: highlightedSentences,
                onHighlightKeyword: (props) => {
                    
                    
                    let keyword = props.keyword.toString();
                    keyword = keyword.substring(keyword.indexOf('/')+1, keyword.lastIndexOf('/')).replaceAll('\\','');
                    let concept = new String();
                    tmpPhrases.map(phrase => {

                        if(phrase.phraseText.localeCompare(keyword) == 0){
                            if(!concept.includes(phrase.concept.conceptName)){
                                concept += `${phrase.concept.conceptName}, `
                            }
                        }
                    });
                    if(concept != ''){
                        for(let child of props.highlightEle.parentElement.children){
                            // if(child.classList.contains('rpv-core__text-layer-text'))
                            if((keyword.toLowerCase().includes(child.innerHTML.toString().toLocaleLowerCase()) || child.innerHTML.toString().toLocaleLowerCase().includes(keyword.toLowerCase())) && child.classList.contains('rpv-core__text-layer-text'))
                            {
                                // child.classList.remove("rpv-core__text-layer-text");
                                child.style.position = ''
                                child.title = concept.substring(0, (concept.length-2));
                            }else if (child.classList.contains('rpv-core__text-layer-text')){
                                let line = child.innerHTML.toString();
                                for(let i=0, j=line.length-1; i<line.length; i++, j--){
                                    
                                    if(keyword.toLowerCase().startsWith(line.substring(i).toLocaleLowerCase())){
                                        child.style.position = ''
                                        child.title = concept.substring(0, (concept.length-2));
                                        break;        
                                    }
                                    if(line.substring(0,j).includes(' ') && keyword.toLowerCase().endsWith(line.substring(0,j).toLocaleLowerCase())){
                                        child.style.position = ''
                                        child.title = concept.substring(0, (concept.length-2));
                                        break;  
                                    }
                                }
                            }
                        }
                        // props.highlightEle.title = concept; //.substring(0, (concept.length-2));
                    }
                },
            },
        },
    });
    const { setTargetPages, clearHighlights, jumpToNextMatch, highlight } = defaultLayoutPluginInstance.toolbarPluginInstance.searchPluginInstance;
    const { jumpToPage } = defaultLayoutPluginInstance.toolbarPluginInstance.pageNavigationPluginInstance;
    
    const handlePageChange = (e) => {
        setPageNumber(e.currentPage+1);
    }

    const toggleHighlight = (checked) =>{
        if(checked != highlighted)
            setHighlighted(checked);
        if(!checked){
            clearHighlights();

        }else{
            
            highlight(highlightedSentences)
            .then((matches) => {
                jumpToPage(pageNumber-1);
            });
        }
    }

    setTargetPages((pageFilter) => {
        let params= new URLSearchParams(window.location.search);
        if(phrases.length <= 0){
            return true;
        }
        if(params.get('pageNumber') != undefined ){
            if(pageFilter.pageIndex == params.get('pageNumber')-1){
                return true;
            }
        }
        for(let phrase of phrases){
            if(pageFilter.pageIndex == phrase.pageNumber-1){
                return true;
            }
        }
        return false;
    });

   const openAddModal=() =>{
    let searchText =null;
    let phrase = null;

    let selectionCount=  window.getSelection().rangeCount;
    let selectedTexts = selectedRows;
    if((null !=  window.getSelection() && undefined != window.getSelection()) &&
      (null != window.getSelection().anchorNode && undefined != window.getSelection().anchorNode)){

        let addedTexts = [];
        for(let i = 0; i<selectionCount; i++){
            searchText = ''
            phrase = window.getSelection().getRangeAt(i).toString();
            if(addedTexts.includes(phrase)){
                continue;
            }
            
            selectedTexts.push({
      
                    conceptName : conceptName,
                    conceptId   : selectedConcepts,    
                    projectId   : parseInt(projectId),      
                    text      : phrase,
                    textSearch  : searchText,
                    documentId  : parseInt(documentId),
                    pageNumber  : pageNumber
                });
           addedTexts.push(phrase);
        } 
        
        setSelectedRows(selectedTexts);
    }

    if(null != selectedRows && selectedRows.length==0){
        failureToaster('Please select text  for creating concept')
        return;
    }
    setSelectPhraseText(phrase);
    setSelectSearchText(searchText);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setSelectedRows(selectedRows);
         setAddModalOpen(true);
         setConceptName('');
         setConceptId('');
         
    }
    const closeAddModal = () => {
      
            setAddModalOpen(false);
            setUpdateModalOpen(false);
            setName('');
            setDescription('');
            setId('');
       
    }

   const addConcept = () =>{


    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
        if(selectedConcepts.length <= 0 && conceptName == ''){
            failureToaster('You must select a concept or enter a concept name.')
            return;
        }
        const id = toast.loading("Please wait...")
        // let selectedRows = [{
      
        //     conceptName : conceptName,
        //     conceptId   : selectedConcepts,    
        //     projectId   : projectId,      
        //     text      : selectPhraseText.toString().replace(selectSearchText, selectSearchText.toString().toUpperCase()),
        //     textSearch  : selectSearchText,
        //     documentId  : documentId,
        //     pageNumber  : pageNumber
        // }];
        let selectedPhrases = selectedRows;
        selectedPhrases =   selectedPhrases.map(selectedPhrase => {
                                selectedPhrase.conceptName = conceptName;
                                selectedPhrase.conceptId = selectedConcepts;
                                return selectedPhrase;  
                            });
        ProjectService.addConcept(selectedPhrases)
                        .then(response => {
                            const channel = new BroadcastChannel('intertext');
                            channel.postMessage('concepts-refresh');
                            toast.update(id, { render: "Phrases added in concept.", type: 'success', autoClose: 3000, isLoading: false });  
                            closeAddModal();
                            setConceptName('');
                            setSelectedConcepts([]);
                            setIsConceptExpanded(true);
                            setSelectedRows([]);
                            fetchConcepts(projectId);
                            window.open(`/pdfviewer?documentId=${documentId}&pageNumber=${pageNumber}&searchText=${searchText}&searchText2=${searchText2}&searchText3=${searchText3}&projectId=${projectId}`, "_self")
                        })
                        .catch(error => { 
                            if(error.response.status == 403){
                                
                                AuthenticationService.handleTokenExpired();
                                return;
                            }
                            toast.update(id, { render: error.message, type: "error", autoClose: 3000, isLoading: false });
                        });
    }

    const closeConcept = () => {
       
        this.setSelectedRows([]);
        $("#addConceptModal").modal("hide");
    }

    const selectedConceptsList = (rows) => {
       
        let selectedConceptIds = new Array(rows.length);
        for(let i=0; i < rows.length; i++){
            selectedConceptIds[i] = rows[i].id;
        }
        setSelectedConceptIds(selectedConceptIds);
    }
    const modalStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        borderRradius: '10px',
        boxShadow: 24,
        p: 4,   
    }

    return (
        <>
        <div>
            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js">
                <div
                    style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        height: '750px',
                    }}
                >
                <Viewer plugins={[defaultLayoutPluginInstance]} onPageChange={handlePageChange} initialPage={pageNumber-1} fileUrl={`${API_URL}/api/documents/download/?id=${documentId}`} />;
                </div>
            </Worker>
        </div>

        <Modal open={addModalOpen} onClose={()=>{closeAddModal()}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Add To concept
                        </Typography><br />
                        <Box id="modal-modal-description" >
                        <Typography color={'error'}>
                                1 sentence will be added to the concept.
                        </Typography><br />
                            <TextField value={conceptName} onChange={(e) => setConceptName(e.target.value)} fullWidth id="outlined-basic" label="New concept name" variant="outlined" />
                        
                            
                            <>
                                <br /><br />
                                
                                <TextField select fullWidth label='Select extsting concepts' displayEmpty
                                    
                                    SelectProps={{
                                        MenuProps: {sx: { maxHeight: '280px'}},
                                        multiple: true,
                                        value: selectedConcepts,
                                        renderValue: (selected) => selected.map((x) => {
                                            let conceptName;
                                            conceptData.map(concept => {
                                                if(concept.id == x){
                                                    conceptName = concept.conceptName;
                                                }
                                            });
                                            return conceptName
                                        }).join(', '),
                                        onChange: (e) => setSelectedConcepts(e.target.value)  
                                  }}
                                >
                                        
                                        {conceptData?.map(concept => (
                                        <MenuItem key={concept.id} value={concept.id}>
                                            <Checkbox checked={selectedConcepts.includes(concept.id)}/>
                                            {concept.conceptName}
                                        </MenuItem>
                                    ))}
                                </TextField> 
                            </>
                           
                            <br /><br />
                            <Button variant="contained" onClick={() =>addConcept() } >Save</Button>&nbsp;
                            <Button variant="outlined" onClick={() => closeAddModal()} color='warning'>Close</Button>
                        </Box>

                    </Box>
                </Modal>
        </>
    )

}

export default PdfViewer