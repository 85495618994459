import React from 'react'

import Header from '../header/header';
import Footer from '../footer/Footer';
import AuthenticationService from '../authentication/AuthenticationService';
const Home = (props) => {
    let isLoggedIn = AuthenticationService.isUserLoggedIn();
   return(
        <div id="mainDiv">
            {isLoggedIn && window.location.pathname !='/' && window.location.pathname !='/logout' && window.location.pathname !='/signup'  && ( //PR Intertext Registration
            <Header />
            )}
            <main role="main" className="inner cover">
                {props.children}
              
            </main>
            {isLoggedIn && window.location.pathname !='/' && window.location.pathname !='/logout'  && window.location.pathname !='/signup' && ( //PR Intertext Registration 
            <Footer /> 
            )}
         </div>
    
    )
}

export default Home