import React from 'react'
import AuthenticationService from './AuthenticationService';
import Login from '../login/loginPage';
import Project from '../project/projectPage';
import { Redirect, Route } from 'react-router-dom';
import SignUp from '../signup/SignUp';



class AuthenticatedRoute extends React.Component {
    render() {

        let { as: Comp, ...props } = this.props;
        return AuthenticationService.isUserLoggedIn() ? <this.props.component />  : ((props.path == '/signup') ? <SignUp /> : <Login />);//PR Intertext Registration 
    }
}

export default AuthenticatedRoute