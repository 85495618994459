
import { Component } from "react";
import axios from 'axios';
import {API_URL} from '../../utils/applicationConstants'
import AuthenticationService from "../../authentication/AuthenticationService";

class UserService extends Component{

    headers(){
        let token =  AuthenticationService.createOAuthToken(AuthenticationService.getSecurityToken());
        return {headers: { 'Authorization': token }, requestType:''}
    }

    findAllUsers(){
        return axios.get(`${API_URL}/api/admin/getUserList`, this.headers());
    }

    updateUserStatus(data){
        return axios.post(`${API_URL}/api/admin/activeOrRejectUser`, data, this.headers());
    }
}


export default new UserService();