import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AuthenticationService from '../authentication/AuthenticationService';
import * as applicationConstants from '../utils/applicationConstants';
import {Grid} from '@mui/material';


const Footer = (props) => {
  //  const [footerClass] = useState("col-md-12 footer py-2 text-white text-center ");
    return(
        <>
        { AuthenticationService.isUserLoggedIn() &&   
        <Grid sx={{backgroundColor:'black',width: '100%', color:'white', textAlign:'center', position: 'fixed', bottom: 0, left:0}}>
             {applicationConstants.COPYRIGHT_TEXT}
        </Grid>
      }
       </>
        )
}
export default Footer;