import axios from 'axios'
import { Component } from 'react';
import {API_URL} from '../../utils/applicationConstants'
import AuthenticationService from '../../authentication/AuthenticationService';

class ProjectService extends Component
{
    headers(){
        let token =  AuthenticationService.createOAuthToken(AuthenticationService.getSecurityToken());
        return {headers: { 'Authorization': token }, requestType:''}
    }
    addProject(project)
    {
        return axios.post(`${API_URL}/api/projects/add`, project, this.headers());        
    }

    addDocumentsInProject(formData){
        let token =  AuthenticationService.createOAuthToken(AuthenticationService.getSecurityToken());
        return axios({
            method: 'post',
            url: `${API_URL}/api/documents/add`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data",
                    'Authorization': token   
                    }
        })
    }

    getAllProject(projectName)
    {
        let token =  AuthenticationService.createOAuthToken(AuthenticationService.getSecurityToken());
        return axios.get(`${API_URL}/api/projects/list?projectName=${projectName}`, {headers: { 'Authorization': token }});
    }
    getProjectByProjectId(projectId)
    {
        let token =  AuthenticationService.createOAuthToken(AuthenticationService.getSecurityToken());

        return axios.get(`${API_URL}/api/projects/detail?id=${projectId}`, {headers: { 'Authorization': token }});
    }
    getConceptsByProjectId(projectId)
    {    
        return axios.get(`${API_URL}/api/concepts/list?projectId=${projectId}`, this.headers());
    }
    deleteProject(projectId)
    {
        return axios.delete(`${API_URL}/api/projects/delete?id=${projectId}`, this.headers());
    }

    deleteDocument(documentId)
    {
        return axios.delete(`${API_URL}/api/documents/deleteDocument?id=${documentId}`, this.headers());
    }

    getDocumentsByProjectId(projectId)
    {
        return axios.get(`${API_URL}/api/documents/loadDocuments?projectId=${projectId}`, this.headers());
    }

    downloadDocument(documentId){
        return axios.get(`${API_URL}/api/documents/download/?id=${documentId}`, this.headers());
    }
    searchText(data){
        return axios.post(
            `${API_URL}/api/documents/search?projectId=${data.projectId}&searchType=${data.searchType}`, data.search, this.headers())
    }

    addConcept(data){
        return axios.post(`${API_URL}/api/concepts/create`, data, this.headers());
    }
    deleteConcept(id){
        return axios.delete(`${API_URL}/api/concepts/delete?id=${id}`, this.headers());
    }
   deletePhrase(id){
       return axios.delete(`${API_URL}/api/phrase/delete?id=${id}`, this.headers());
     }
   getPhraseByConceptId(id){
    return axios.get(
        `${API_URL}/api/phrase/list?conceptId=${id}`, this.headers())
   }
   
   getPhraseByDocumentId(id){
    return axios.get(
        `${API_URL}/api/phrase/list/bydocument?documentId=${id}`, this.headers())
   }
   extractDocument(id){
        return axios.get(`${API_URL}/api/documents/extract?documentId=${id}`, this.headers())
   }
   getConceptsSummery(projectId, conceptIds){
        let headers = this.headers() 
        headers.responseType = 'arraybuffer';
        return axios.post(`${API_URL}/api/concepts/summary?id=${projectId}`, conceptIds, headers);
   }

   sendErrorLogsToServer(logs){
     return axios.post(`${API_URL}/api/logs`, logs, this.headers()) ;  
   }
}    
export default new ProjectService();