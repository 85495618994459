import React from 'react';

import './App.css';
import { Router } from "@reach/router"
import { Redirect, Route } from 'react-router-dom';
//import Logout from './header/logout';
import Login from './login/loginPage'
import Home from './home/homePage';
import Project from './project/projectPage';
import Docuemnt from './document/documentPage'

import "jquery/dist/jquery.min";
import "bootstrap/dist/js/bootstrap.min";
import AuthenticatedRoute from './authentication/authenticatedRoute';

//import CloseIFrame from './utils/closeIFrame';
import * as applicationConstants from './utils/applicationConstants';
import ProjectDetail from './project/projectDetail';
import PdfViewer from './document/pdfViewer';
import SignUp from './signup/SignUp';
import UserManagement from './user/UserManagement';

/*
 *CPJRS 07/26/2022 PR Intertext Project : Provide feature for Registration & Sign-in 
 */

let loc_array = window.location.href.split("/");
let tabValue = loc_array[loc_array.length - 1];
class App extends React.Component {


  constructor(props) {
    super(props);

    this.state = { logginStatus: true };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];

    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }

  clearTimeout() {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    this.logoutTimeout = setTimeout(this.logout, 14400 * 1000);
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  logout() {
 
    // Send a logout request to the API
    //console.log("Sending a logout request to the API...");
  
   
      window.location.href = "/logout";
    
    
   
  }

  destroy() {
    this.clearTimeout();
    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }
  render() {
  return (

    <div>
    <Home>
      <Router>


    <Login default />
    <AuthenticatedRoute component={SignUp} path="/signup"></AuthenticatedRoute>  
    <AuthenticatedRoute Login path="/login" /> 
               
            
    < AuthenticatedRoute component={Project} path="/Project" ></ AuthenticatedRoute>
    < AuthenticatedRoute component={Docuemnt} path="/Document" ></ AuthenticatedRoute>
    < AuthenticatedRoute component={ProjectDetail} path="/Project/details" ></ AuthenticatedRoute>
    < AuthenticatedRoute component={PdfViewer} path="/pdfviewer" ></ AuthenticatedRoute>
    < AuthenticatedRoute component={UserManagement} path="/users" ></ AuthenticatedRoute> 
              
                
      </Router>
    </Home>
    </div>
  );

}

}

export default App;