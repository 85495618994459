import React, { Component } from 'react'
import { FaAngleDoubleDown } from 'react-icons/fa'
import { API_URL, SUPPORTED_FILES } from '../utils/applicationConstants'
import ProjectService from '../services/project/ProjectService';
import { failureToaster, successToaster } from '../utils/Toaster';
import $ from 'jquery'; 
import { toast } from'react-toastify';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import AuthenticationService from '../authentication/AuthenticationService';
import { SaveAlt, DeleteForeverRounded, RestartAlt, CloudUpload, RemoveCircle, RemoveRedEye, AddCircleOutlined, Print,  AddCircleOutlineOutlined, Refresh } from '@mui/icons-material';
import MUIDataTable from 'mui-datatables';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { CircularProgress,Divider,Container, Tooltip, Button, Box, Tabs, Tab, Grid, Typography, Modal, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, LinearProgress, Accordion, AccordionSummary, AccordionDetails, TextField, Checkbox, Select, MenuItem, Chip} from '@mui/material';
import TabPanel from './TabPanel'

class ProjectDetail extends Component {

    constructor(){
        super();
        this.state = {
            projectId: '',
            isDocumentExpanded: false,
            isConceptExpanded: false,
            documents: [],
            project: {},
            selectedRows: [],
            searchTextData: [],
            searchText: '',
            searchText2: '',
            searchText3: '',
            isUploadDocumentExpanded: '',
            selectedFiles: [],
            conceptData: [],
            phrases: [],
            conceptName: '',
            conceptId: [],
            selectedConcepts: [],
            viewDocument: {},
            selectedConceptIds: [],
            currentTab: 0,
            deleteModalOpen: false,
            deleteId: '',
            deleting: '',
            deleteText: '',
            uploadProgress: 0,
            addModalOpen: false,
            isSearchLoading: false,
            searchType: false
        }


        this.deleteDocument = this.deleteDocument.bind(this);
        this.addFilesToUpload = this.addFilesToUpload.bind(this);
        this.uploadDocuments = this.uploadDocuments.bind(this);
        this.removeSelectedFile = this.removeSelectedFile.bind(this);
        this.addConcept = this.addConcept.bind(this);
        this.openDocumentInViewer = this.openDocumentInViewer.bind(this);
        this.validateAddConcept = this.validateAddConcept.bind(this);
        this.updateConceptNameOrId = this.updateConceptNameOrId.bind(this);
        this.extractDocument = this.extractDocument.bind(this);
        this.printConceptSummary = this.printConceptSummary.bind(this);
        this.closeConcept = this.closeConcept.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.delete = this.delete.bind(this);
        this.fileDropProcessor = this.fileDropProcessor.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);
        this.addConceptFromRow = this.addConceptFromRow.bind(this);
    }

    componentDidMount() {
        
        const projectId = (new URLSearchParams(window.location.search)).get('projectId');
        this.setState({projectId: projectId});

        ProjectService.getDocumentsByProjectId(projectId)
                        .then(response => {
                            this.setState({documents: response.data})
                        })
                        .catch(error => { 
                            if(error.response.status == 403){
                                
                                AuthenticationService.handleTokenExpired();
                                return;
                            }
                            failureToaster(error.message)
                        });
        
        ProjectService.getProjectByProjectId(projectId)
                        .then(response => {
                            this.setState({project: response.data})
                        })
                        .catch(error => { 
                            if(error.response.status == 403){
                                
                                AuthenticationService.handleTokenExpired();
                                return;
                            }
                            failureToaster(error.message)
                        });

        this.fetchConcepts(projectId);

        const channel = new BroadcastChannel('intertext');
        channel.addEventListener ('message', (event) => {
            if(event.data == 'concepts-refresh'){
                this.fetchConcepts(this.state.projectId);            
            }
        });
           
    }
    fetchConcepts(projectId){
        ProjectService.getConceptsByProjectId(projectId)
                        .then(response => {
                            let conceptData = response.data;
                            if(undefined != conceptData && conceptData.length == 0){
                                this.setState({conceptData: []})
                            }
                            for(let i=0; i<conceptData.length; i++){
                                
                                ProjectService.getPhraseByConceptId(conceptData[i].id)
                                                .then(result => {
                                                    conceptData[i].phrases = result.data
                                                    
                                                    this.setState({conceptData: response.data})
                                                })
                                                .catch(error => { 
                                                    if(error.response.status == 403){
                                                        
                                                        AuthenticationService.handleTokenExpired();
                                                        return;
                                                    }
                                                    failureToaster(error.message)
                                                });
                                            }

                        })
                        .catch(error => { 
                            if(error.response.status == 403){
                                
                                AuthenticationService.handleTokenExpired();
                                return;
                            }
                            failureToaster(error.message)
                        });
    }
    validateSearchText(value){
        
        if(undefined != value && '' != value && null != value){
            return  true;
        }
  
        return false;
    }
    searchTextData(){
      
        if(!this.validateSearchText(this.state.searchText) && !this.validateSearchText(this.state.searchText2) && !this.validateSearchText(this.state.searchText3)){
            failureToaster('You must enter value in at least one search box!');
            return false;
        }
        this.setState({isSearchLoading:true});
        let searchArr = new Array();
        if(this.validateSearchText(this.state.searchText)){
            searchArr.push(this.state.searchText);
        }
        if(this.validateSearchText(this.state.searchText2)){
            searchArr.push(this.state.searchText2);
        }
        if(this.validateSearchText(this.state.searchText3)){
            searchArr.push(this.state.searchText3);
        }

        ProjectService.searchText({search: searchArr, projectId: this.state.projectId, searchType: this.state.searchType?'AND':'OR'})
                        .then(response => {
                            this.setState({searchTextData: response.data, isSearchLoading: false});
                        })
                        .catch(error =>{ 
                            this.setState({isSearchLoading: false});
                            if(error.response.status == 403){
                                
                                AuthenticationService.handleTokenExpired();
                                return;
                            }
                            failureToaster(error.message)
                            
                        });
    }

    openDeleteModal(id, deleting,deleteText){
      
        this.setState({
            deleteModalOpen: true,
            deleteId: id,
            deleting: deleting,
            deleteText: deleteText
        });
    }
    closeDeleteModal(){
        this.setState({
            deleteModalOpen: false,
            deleteId: '',
            deleting: '',
            deleteText: ''
        });    
    }
    delete(){
        if(this.state.deleting == 'document'){
            this.deleteDocument(this.state.deleteId);
        }else if(this.state.deleting == 'concept'){
            this.deleteConceptById(this.state.deleteId);
        }else if(this.state.deleting == 'phrase'){
            this.deletePhraseById(this.state.deleteId);
        }
    }
    deleteDocument(documentId){
        const id = toast.loading("Please wait...")
            ProjectService.deleteDocument(documentId)
            .then(response => {
                if(response.data.message == 'Success'){
                    toast.update(id, { render: "Document deleted successfully.", type: "success", autoClose: 3000, isLoading: false });                       
                    this.setState({
                        deleteModalOpen: false,
                        deleteId: '',
                        deleting: '',
                        deleteText: ''
                    });  
                }else{
                    toast.update(id, { render: response.data.message, type: "error", autoClose: 3000, isLoading: false });
                }
                this.fetchDocumentData();
                
            })
            .catch(error => { 
                if(error.response.status == 403){
                    
                    AuthenticationService.handleTokenExpired();
                    return;
                }
                toast.update(id, { render: error.message, type: "error", autoClose: 3000, isLoading: false });
            });            
    }

    fetchDocumentData(){
        ProjectService.getDocumentsByProjectId(this.state.projectId)
        .then(response => {
            this.setState({documents: response.data})    
        })
        .catch(error => { 
            if(error.response.status == 403){
                
                AuthenticationService.handleTokenExpired();
                return;
            }
            failureToaster(error.message)
        });
    }

    addFilesToUpload(files){
        
        let selectedFiles = this.state.selectedFiles
        for(let file of files){
            selectedFiles.push(file);
        }

        this.setState({
            selectedFiles: selectedFiles,
            uploadProgress: 0
        });
    }
    
    fileDropProcessor(e){
        let files = new Array();
        for(let item of e.dataTransfer.items){
            files.push(item.getAsFile())
        }
        this.addFilesToUpload(files);
    }
    removeSelectedFile(index){
        let selectedFiles = this.state.selectedFiles;
        selectedFiles.splice(index, 1);
        this.setState({selectedFiles: selectedFiles});

    }
    extractDocument(id){
        const toastId = toast.loading("Extracting the document, please wait...");
        ProjectService.extractDocument(id)
        .then(response => {
            toast.update(toastId, { render: 'Document extracted', type: 'success', isLoading: false, autoClose: 3000  });                                   
            this.fetchDocumentData();
        })
        .catch(error => { 
            if(error.response.status == 403){
                
                AuthenticationService.handleTokenExpired();
                return;
            }
            toast.update(id, { render: error.message, type: "error", autoClose: 3000, isLoading: false });
        });
    }
    uploadDocuments(){

        const files = this.state.selectedFiles
        if(this.isValidated(files)){
            
            const Msg = ( {uploaded, progress} ) => (
                <div>
                    {uploaded}/{files.length} files uploaded.
                    <div className="progress">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{width: `${progress}%`}}></div>
                    </div>
                </div>
            )
                      
            //const id = toast.loading(<Msg uploaded="0" progress="0" />, {icon: false, autoClose: false, hideProgressBar: true})
            
            let formData = new FormData();
            formData.append('projectId', this.state.projectId);

            for(let file of files){
                formData.append('files', file);
            }
            const parent = this;
            let token =  AuthenticationService.createOAuthToken(AuthenticationService.getSecurityToken());
            parent.setState({
                uploadProgress: 1
            });
            fetchEventSource(`${API_URL}/api/documents/add`,{

                method: 'post',
                body: formData,
                headers: { //"Content-Type": "multipart/form-data",
                    'Authorization': token
                },
                observe(observer){
                    console.log(observer);
                },                    
                onopen(res) {
                  if (res.ok && res.status === 200) {
                   
                    parent.setState({
                        uploadProgress: 10
                    });
                  } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                  ) {
                   
                  }
                },
                onmessage(event) {
                    const response = JSON.parse(event.data)
                  
                    
                    if(response.message == 'AlreadyExists'){
                        // let toastType = "warning"
                        if(response.existingFiles != null && response.existingFiles.length == files.length){
                            let toastmsg = 'All documents are already exists'
                            let toastType = "warning";
                            //toast.update(id, { render: toastmsg, type: toastType, isLoading: false, autoClose: 3000  });
                            toast.warn(toastmsg);    
                            
                            parent.setState({
                                uploadProgress: 0
                            });
                        }else{
                            let toastmsg = 'Some files already exists: '+response.existingFiles;
                            toast.warn(toastmsg);    
                        }
                    }
                    else if(response.message == 'SingleSuccess'){
                        //let toastmsg = `${response.uploadCount}/${files.length} files uploaded`;
                        //let toastType = "loading";
                       
                        parent.setState({
                            uploadProgress: (Math.round((response.uploadCount * 100)/files.length))
                        });
                        //toast.update(id, { render: <Msg uploaded={response.uploadCount} progress={((response.uploadCount * 100)/files.length)} />, type: toastType, isLoading: false  });
                        
                    }
                    else if(response.message == 'Success'){
                        if(response.storedDocuments != null && response.storedDocuments.length >= 1){
                            let toastmsg = 'Documents added successfully!'
                            // let toastType = "success";
                            //toast.update(id, { render: toastmsg, type: toastType, isLoading: false, autoClose: 3000  });
                            successToaster(toastmsg);

                            parent.fetchDocumentData();
                            $("#documentFile").val('')
                            parent.setState({selectedFiles: [], uploadProgress: 100})
                        }
                        
                    }
                  //console.log(event.data.message);
                },
                onclose() {
                  console.log("Connection closed by the server");
                }
            });
            // ProjectService.addDocumentsInProject(formData)
            //                 .then(response => {
            //                     if(response.data.message == 'Success'){
            //                         let toastmsg = 'Documents added successfully!'
            //                         let toastType = "success";
            //                         if(null != response.data.existingFiles && response.data.existingFiles.length > 0 ){
            //                             toastType = "warning"
            //                             toastmsg += ' Some files already exists: '+response.data.existingFiles;
            //                         }
            //                         toast.update(id, { render: toastmsg, type: toastType, isLoading: false, autoClose: 3000  });
                                    
            //                         this.fetchDocumentData();
            //                        $("#documentFile").val('')
            //                        this.setState({selectedFiles: []})
            //                     }
            //                 })
            //                 .catch(error => toast.update(id, { render: error.message, type: "error", isLoading: false, autoClose: 3000 }));
        }
    }

    isValidated(files){
        if(files.length <= 0){
            failureToaster("No file selected!");
            return false;
        }
        for(let file of files){
            let ext = file.name.split('.').pop();
            if(!SUPPORTED_FILES.includes(ext,0)){
                failureToaster("Only pdf file is require. Document file :"+file.name+" is not a Pdf.");
                return false;
            }
        }
        return true;
    }

    rowSelectfunction(row){
        let selectedRows = this.state.selectedRows;
        let i = selectedRows.indexOf(row) ;
        if(i != -1){
            selectedRows.splice(i, 1);
        }else{
            selectedRows.push(row);
        }
        this.setState({selectedRows: selectedRows});
        
    }
    updateConceptNameOrId(target){
     
            let dataValue = '';
            $("#conceptoptions option").each(function(){
                if(target.value == $(this).val()){
                    dataValue = $(this).data().value;
                    return;
                }
            });
            this.setState({
                conceptName: target.value,
                conceptId:  dataValue
            });            
        
    }
    validateAddConcept(){
        if(this.state.selectedRows.length <= 0){
            failureToaster("You must select atleast one row to add to concept.");
            return;
        }
        
       
        this.openAddModal();
    }
    addConceptFromRow(rowIndex){
        let selectedRows = this.state.selectedRows;
        debugger;
        let i = selectedRows.indexOf(this.state.searchTextData[rowIndex]) ;
        if(i == -1){
            selectedRows.push(this.state.searchTextData[rowIndex]);
            this.setState({selectedRows: selectedRows});
        }
   
        this.validateAddConcept();
    }
    addConcept(){

        if(this.state.selectedConcepts.length <= 0 && this.state.conceptName == ''){
            failureToaster('You must select a concept or enter a concept name.')
            return;
        }
        const id = toast.loading("Please wait...")
        let selectedRows = this.state.selectedRows;
        for(let i=0; i<selectedRows.length; i++){
            selectedRows[i].conceptName = this.state.conceptName;
            selectedRows[i].conceptId = this.state.selectedConcepts;                    
        }
        ProjectService.addConcept(selectedRows)
                        .then(response => {
                            let successMessage = '';
                            
                            if(null !=response.data && response.data.existingFiles.length==0){
                                successMessage += "Phrases added in concept.";
                            }
                            else{
                                if(null !=response.data && response.data.existingFiles.length>0){

                                    successMessage = response.data.existingFiles.length+' phrases already added in selected concept';
                                  }
                                  if(selectedRows.length != response.data.existingFiles.length){
                                      successMessage += " and remaining Phrases added in concept.";
                                  }
                            }    
                            
                             
                            toast.update(id, { render: successMessage, type: 'success', autoClose: 3000, isLoading: false });  
                            this.closeAddModal();
                            this.setState({conceptName: ''});
                            this.setState({selectedConcepts: []});
                            this.setState({isConceptExpanded: true})
                            this.setState({  selectedRows: [], })
                           // this.fetchConcepts(this.state.projectId);
                        })
                        .catch(error => { 
                            if(error.response.status == 403){
                                
                                AuthenticationService.handleTokenExpired();
                                return;
                            }
                            toast.update(id, { render: error.message, type: "error", autoClose: 3000, isLoading: false });
                        });
    }

    closeConcept(){
       
        this.setState({  selectedRows: [], });
        $("#addConceptModal").modal("hide");
    }
   
    deleteConceptById(id){
        const toastId = toast.loading("Please wait...")
            ProjectService.deleteConcept(id)
                            .then(response => {
                                toast.update(toastId, { render: "Concept deleted", type: "success", autoClose: 3000, isLoading: false });
                                this.fetchConcepts(this.state.projectId);
                                this.closeDeleteModal();
                            })
                            .catch(error => { 
                                if(error.response.status == 403){
                                    
                                    AuthenticationService.handleTokenExpired();
                                    return;
                                }
                                toast.update(toastId, { render: error.message, type: "error", autoClose: 3000, isLoading: false });
                            });
        
                                        
    }
    deletePhraseById(id){
        const toastId = toast.loading("Please wait...")
            ProjectService.deletePhrase(id)
                            .then(response => {
                                toast.update(toastId, { render: "Phrase deleted", type: "success", autoClose: 3000, isLoading: false });
                                
                                this.fetchConcepts(this.state.projectId);
                                this.closeDeleteModal();
                            })
                            .catch(error => { 
                                if(error.response.status == 403){
                                    
                                    AuthenticationService.handleTokenExpired();
                                    return;
                                }
                                toast.update(toastId, { render: error.message, type: "error", autoClose: 3000, isLoading: false });
                            });
     
                  
    }
    openDocumentInViewer(documentId, pageNumber, totalPages){
        window.open(`/pdfviewer?documentId=${documentId}&pageNumber=${pageNumber}&searchText=${this.state.searchText}&searchText2=${this.state.searchText2}&searchText3=${this.state.searchText3}&projectId=${this.state.projectId}`)
    }

    // setSelectedConcepts(rows){
    //     let selectedConceptIds = new Array(rows.selectedRows.length);
    //     for(let i=0; i < rows.selectedRows.length; i++){
    //         selectedConceptIds[i] = rows.selectedRows[i].id;
    //     }
    //     this.setState({
    //         selectedConceptIds: selectedConceptIds
    //     });
    // }

    setSelectedConcepts(rows){
       
        let selectedConceptIds = new Array(rows.length);
        for(let i=0; i < rows.length; i++){
            selectedConceptIds[i] = rows[i].id;
        }
        this.setState({
            selectedConceptIds: selectedConceptIds
        });
    }
    
    printConceptSummary(){
        if(this.state.selectedConceptIds.length <= 0){
            failureToaster("Please select at least one concept!");
            return;
        }
        ProjectService.getConceptsSummery(this.state.projectId, this.state.selectedConceptIds)
                        .then(response => {

                            let blob = new Blob([response.data], {type: "application/pdf"});
                            let fileUrl = window.URL.createObjectURL(blob);
                            var myWindow = window.open("", "", `width=${window.innerWidth},height=${window.innerHeight}`);
                            myWindow.document.write(`<iframe height="100%" width="100%" src="${fileUrl}"></iframe>`);

                        })
                        .catch(error => { 
                            if(error.response.status == 403){
                                
                                AuthenticationService.handleTokenExpired();
                                return;
                            }
                            failureToaster(error.message)
                        });
    }

    openAddModal(){
        this.setState({addModalOpen: true, conceptId: '', conceptName: ''});

    }
    closeAddModal(){
        this.setState({
            addModalOpen: false,
            updateModalOpen: false,
            name: '',
            description: '',
            id: '',
        });    
    }


    getMuiTheme = () => createTheme({
        components: {
            MUIDataTable: {
            styleOverrides: {
                root: {
                backgroundColor: 'red',
                },
                paper: {
                boxShadow: 'none',
                },
            },
            },
            MuiTableCell: {
            styleOverrides: {
                head: {
                backgroundColor: '#eaeaea5c !important',
                color:  '#1a58b5 !important',
                fontSize: '1.1em !important'
                },
            },
            },
        },
});
    render() {
    const {searchType,documents,  project, searchText, searchText2, searchText3,  selectedFiles , conceptData, selectedRows, conceptName, viewDocument, currentTab, deleteModalOpen, deleting, uploadProgress, addModalOpen, conceptId, deleteText,isSearchLoading } = this.state;
    
    const modalStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        borderRradius: '10px',
        boxShadow: 24,
        p: 4,   
    }

    const documentColumns = [
        {
            name: 'documentName',
            label: 'Name',
            options: {
                filter: true,
                sort: false,
            }        
        },
        {
            name: 'numberOfPages',
            label: 'Total Pages',
            options: {
                filter: false,
                sort: false,
                setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                    style: { 
                        minWidth: "140px" }
                })
            }   
        },
        {
            label: 'Status',
            name: 'status',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let color = (value == 'EXTRACTED') ? 'success': (value == 'PENDING')? 'warning': 'info';
                    return <Chip label={value} size="small" color={color} />
                }
            }
        },
        {
            label: 'Uploaded Date',
            name: 'auditTimestamp',
            options: {
                filter: true,
                sort: false,
                setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                    style: { 
                        minWidth: "160px" }
                })
            }
        },
        {   
            name: 'id',
            label: 'Actions',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const status = this.state.documents[tableMeta.rowIndex].status;
                    const documentName = this.state.documents[tableMeta.rowIndex].documentName;
                    return (
                        <>
                            {(status == 'PENDING' || status == 'PROCESSING') && (
                                <Tooltip title='Restart Processing'>
                                    <RestartAlt id={`extract-button${value}`} onClick={(e) => {this.extractDocument(value); $(`#extract-button${value}`).hide()}} color="warning"   style={{cursor: 'pointer'}} />
                                </Tooltip>
                            )}
                            <a href={`${API_URL}/api/documents/download/?id=${value}`}   download='file'>
                                <Tooltip title='Download Document'>
                                    <SaveAlt color="primary"   style={{cursor: 'pointer'}} />
                                </Tooltip>
                            </a>
                            <Tooltip title='View Doument'>
                                    <RemoveRedEye sx={{cursor: 'pointer'}} color='primary' onClick={(e) => {window.open(`/pdfviewer?documentId=${value}&pageNumber=${1}&projectId=${this.state.projectId}`)}} />
                            </Tooltip>
                            <Tooltip title="Delete Document">
                                <DeleteForeverRounded color="error" onClick={() => this.openDeleteModal(value, 'document',documentName)} style={{cursor: 'pointer'}}  />
                            </Tooltip>
                        </>)
                },
                setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                    style: { 
                        minWidth: "140px" }
                })
            }
        }
        ];

        
        const searchTextColumns = [
            {
                label: 'Document Name',
                name: 'documentName',
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                        style: { 
                            minWidth: "160px" }
                    })
                }
            },
            {
                label: 'Occurrence in Doc',
                name: 'text',
                options: {
                    filter: false,
                    sort: false,
                    
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div dangerouslySetInnerHTML={{__html: value}} />
                }
                },
            },
            {
                label: 'Page No',
                name: 'pageNumber',
                options: {
                    filter: false,
                    sort: false,
                    setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                        style: { 
                            minWidth: "140px" }
                    })
                }
                
            },
            {   
                name: 'documentId',
                label: 'Actions',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let totalPages = this.state.searchTextData[tableMeta.rowIndex].totalPages;
                        let pageNumber = this.state.searchTextData[tableMeta.rowIndex].pageNumber;

                        return (
                            <>
                                <Tooltip title='View in Doument'>
                                    <RemoveRedEye sx={{cursor: 'pointer'}} color='primary' onClick={(e) => {this.openDocumentInViewer(value, pageNumber, totalPages); }} />                                    
                                </Tooltip>
                                <Tooltip title='Add Concept'>
                                    <AddCircleOutlined sx={{cursor: 'pointer'}} color='primary' onClick={() => {this.addConceptFromRow(tableMeta.rowIndex)}} />
                                </Tooltip>
                            </>)
                    },
                }
            }
            // {
            //     name: 'Concept',
            //     selector: row => row.conceptName,
            //     sortable: true,
            //     reorder: true,
            //     width: '10em',
            // },
            ];

            const conceptOptions = {
                customToolbar: () => {

                    return(
                        <>
                            <Button>
                                <Print  color="blue" onClick={this.printConceptSummary} style={{cursor: 'pointer'}}  />
                            </Button>
                            <Tooltip title="Refresh Concept">
                                <Refresh color="primary" onClick={() => this.fetchConcepts(this.state.projectId)}  style={{cursor: 'pointer'}}  />
                            </Tooltip>
                        </>
                      
                      );
                },
                filterType: 'textField',
                selectableRows: true,
                selectableRowsHeader: true,
                selectToolbarPlacement: 'above',
              //   selectableRowsOnClick: true,
                onRowsSelect : (curRowSelected, allRowsSelected) => {
                   
                    let rows =[];
                 
                    // this.setSelectedConcepts(allRowsSelected);
                    for(let i=0;i<allRowsSelected.length;i++){
                            if(!rows.includes(allRowsSelected[i].index))
                            rows.push(conceptData[allRowsSelected[i].index]);
                    }
                    this.setSelectedConcepts(rows);
                },
                onRowsDelete:(e)=>  this.setSelectedConcepts([]),    
                download: false,
                print: false,
                viewColumns: false,
                rowsPerPage: 15,
                expandableRows: true,
                expandableRowsHeader: false,
                renderExpandableRow: (rowData, rowMeta) => { 
                let phrases = conceptData[rowMeta['rowIndex']].phrases;
                for(let i=0; i<phrases.length; i++){
                    phrases[i].conceptName = conceptData[rowMeta['rowIndex']].conceptName;
                }
                return ( 
                 <React.Fragment>
                        <tr>
                          <td colSpan={12}>
                                <MUIDataTable  
                                title='Phrases List'
                                data={phrases}
                                columns={phraseColumns}
                                options={phraseOptions}
                                /> 
                             
                           </td> 
                         </tr> 
               </React.Fragment>  
                    
                )}
        };
        const conceptColumns = [
            {
                
                name: 'conceptName',
                label: "Concept Name",
                options: {
                    filter: true,
                    sort: false,
                   
                }
            },
             {   
                name: 'id',
                label: 'Actions',
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const conceptName = this.state.conceptData[tableMeta.rowIndex].conceptName;
                        return (
                            <>
                              
                                <Tooltip title="Delete Concept">
                                    <DeleteForeverRounded color="error" onClick={() => this.openDeleteModal(value, 'concept',conceptName)}  style={{cursor: 'pointer'}}  />
                                </Tooltip>
                            </>)
                    }
                }
            }
        ];   
        const phraseOptions = {
            filter: false,
            selectableRows: false,
            download: false,
            print: false,
            viewColumns: false,
            rowsPerPage: 15,

        }    

        const phraseColumns = [
            {

                name: 'documentName',
                label: "Document Name",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                        style: { 
                            maxWidth: "140px" ,
                            overflowWrap: 'break-word'
                        }
                    })
                }
            },
            {

                name: 'searchText',
                label: "Search Text",
                options: {
                    filter: true,
                    sort: false,
                   
                }
            },
            {

                name: 'phraseText',
                label: "Phrase Text",
                options: {
                    filter: true,
                    sort: false,
                   
                }
            },
            {

                name: 'pageNumber',
                label: "Page Number",
                options: {
                    filter: true,
                    sort: false,
                   
                }
            },
            {
                name: 'documentId',
                options: {
                    display: false, 
                }
            },
            {
                name: 'conceptName',
                options: {
                    display: false, 
                }
            },
            {   
               name: 'id',
               label: 'Actions',
               options: {
                   filter: false,
                   sort: false,
                   customBodyRender: (value, tableMeta, updateValue) => {
                     //  debugger;
                    
                    const pageNumber = tableMeta.currentTableData[tableMeta.rowIndex].data[3];
                    
                    const documentId = tableMeta.currentTableData[tableMeta.rowIndex].data[4];
                    return (
                           <>
                             
                               <Tooltip title="Delete Phrase">
                                   <DeleteForeverRounded color="error" onClick={() => this.openDeleteModal(value, 'phrase')}  style={{cursor: 'pointer'}}  />
                               </Tooltip>
                                <Tooltip title='View Doument'>
                                    <RemoveRedEye sx={{cursor: 'pointer'}} color='primary' onClick={(e) => {window.open(`/pdfviewer?documentId=${documentId}&pageNumber=${pageNumber}&projectId=${this.state.projectId}`)}} />
                                </Tooltip>
                           </>)
                   }
               }
           }
        ];  
           const conditionalRowStyles  = [
               {
                   when: row => selectedRows.includes(row),
                   style: {
                       backgroundColor: '#007bff',
                       color:'#fff'
                   }
               }
           ];
           const tabIndex = (index) => {
                return {
                    id: `simple-tab-${index}`,
                    'aria-controls': `simple-tabpanel-${index}`,
                };
           }

        const options = {
            filterType: 'textField',
            selectableRows: false,
            download: false,
            print: false,
            viewColumns: false,
            rowsPerPage: 15,
         
        };

        const searchTextOptions = {
            selectableRows: true,
            download: false,
            print: false,
            viewColumns: false,
            rowsPerPage: 15,
            filter: false,
            search: false,
            selectableRows: false,
            selectToolbarPlacement: 'above',
            onCellClick: (colData, cellMeta) => {
                if(cellMeta.colIndex != 3){
                    this.rowSelectfunction(this.state.searchTextData[cellMeta.rowIndex]);  
                }
            },
            setRowProps: (row, dataIndex, rowIndex) => {
                let rowData = this.state.searchTextData[rowIndex];
                let i = this.state.selectedRows.indexOf(rowData);
                if(i > -1){
                    return {
                        style: { backgroundColor: '#2196f3' },
                    };    
                }
                return {}
            },
            customToolbar: () => {

                return(
                        <Button  onClick={this.validateAddConcept} variant='contained' color='primary'>
                            <AddCircleOutlineOutlined  color="inherit"  style={{cursor: 'pointer'}}  /> &nbsp;
                            <Typography variant='button' style={{ textTransform:'capitalize'}}>
                                Add Concept
                            </Typography>
                            
                        </Button>
                  );
            },
        
        }

        function FileInput({ parent }){
            const fileInputRef = React.createRef();
            return (
                <>
                    <Button onClick={() => fileInputRef.current.click()} variant='contained' sx={{borderRadius: '25px', padding:'0.8em', textTransform: 'capitalize'}}><CloudUpload  /> &nbsp; Multiple Files</Button>                    
                    <input type="file" ref={fileInputRef} style={{display: 'none'}} multiple onChange={(e) => {parent.addFilesToUpload(e.target.files); }}  id="documentFile" />
                </>
            )
        }
        
        const handleTabChange= (event, newVal) =>{
            
            if(newVal == 1){
                this.fetchConcepts(this.state.projectId);
            }
            this.setState({currentTab: newVal});
        }
        const ProjectDescription = () => {
            return (
                <Accordion>
                    <AccordionSummary  expandIcon={<FaAngleDoubleDown />}>
                        <Typography variant='h6' > <b style={{color: '#1983cf'}}>Project Name: </b> {project?.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* <Typography variant='h5'  color='primary'>{project?.name}</Typography> */}
                        <Grid container>  
                        <Grid item xs={10}> 
                        { project?.description && 
                        <Typography variant="body1" gutterBottom>
                         <b style={{color: '#1983cf'}}> Project Description:</b>   {project?.description}
                        </Typography>
                        }
                          </Grid>
                        <Divider orientation="vertical" variant="middle" flexItem>
                        <Grid item xs={2}>
                        <Typography  sx={{textTransform: 'capitalize'}} variant="overline" display="block" gutterBottom>
                        <b style={{color: '#1983cf'}}> Created Date:</b> {project?.createdDate}
                        </Typography>
                        </Grid>
                        </Divider>
                       </Grid> 
                    </AccordionDetails>
                </Accordion>
            )
        }
        return (
            <>
            
            <Modal open={deleteModalOpen} onClose={this.closeDeleteModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <center>
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" >
                            Confirm
                        </Typography><br />
                        <Box id="modal-modal-description" >
                            <Typography id="modal-modal-title" variant="p">
                                Are you sure you want to delete the <b>{deleteText}</b> {deleting} ?
                            </Typography><br /><br />
                            <Button variant="outlined" sx={{borderRadius: '20px', textTransform:'capitalize', ':hover':{color:'#fff',backgroundColor: 'primary.main'}}} onClick={this.delete} >Yes</Button>&nbsp;
                            <Button variant="outlined" sx={{borderRadius: '20px', textTransform:'capitalize', ':hover':{color:'#fff',backgroundColor: 'warning.main'}}} onClick={this.closeDeleteModal} color='warning'>No</Button>
                        </Box>

                    </Box>
                    </center>
                </Modal>
            <Grid>  
            <Grid sx={{padding: '0.7em', marginTop: '1em'}} container>
                <Grid item xs={11}>
                    {/* <Typography variant='h5'>Project name: {project?.name}</Typography> */}
                </Grid>  
              
                <Grid item>
                    <a  href="/Project" className='btn btn-primary'>Back</a>
                </Grid>
          </Grid>
                
            <Grid container  sx={{justifyContent:"center"}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {/* <Tabs value={currentTab}  onChange={(event, newVal) => {this.setState({currentTab: newVal})} */}
                <Tabs value={currentTab}  onChange={handleTabChange} 
            aria-label="basic tabs example">
                    <Tab label="Documents" {...tabIndex(0)} />
                    <Tab label="Concepts" {...tabIndex(1)} />
                    <Tab label="Search Text" {...tabIndex(2)} />
                </Tabs>
            </Box>
                
            </Grid>
            <TabPanel value={currentTab} index={0}>
                
                <Container style={{marginTop: '1em'}}>
                    <ProjectDescription />
                    <Accordion  style={{marginTop: '1em'}}>
                        <AccordionSummary  expandIcon={<FaAngleDoubleDown />}>
                            <Typography variant='h6'>Upload Documents</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid container sx={{  padding: '1em'}}>
                                
                                <Grid item xs={10} >
                                    <FileInput parent={this} />
                                </Grid>
                                <Grid item xs={2} >
                                    <Button onClick={this.uploadDocuments} variant='contained' style={{textTransform: 'capitalize'}} disabled ={selectedFiles.length <= 0 }>Upload All</Button>
                                </Grid>
                                <Grid item xs={12} sx={{mt: '1em', marginBottom: '0.5em'}}>
                                    <Grid item xs={12}>
                                    <Typography variant="button" style={{textTransform: 'capitalize'}}  display="block" gutterBottom>
                                        Upload Progress:
                                    </Typography>
                                    </Grid>
                                    {
                                        uploadProgress > 0 ?
                                        
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box sx={{ width: '100%', mr: 1 }}>
                                                    <LinearProgress variant="determinate" value={uploadProgress} />
                                                </Box>
                                                <Box sx={{ minWidth: 35 }}>
                                                    <Typography variant="body2" color="text.secondary">{`${uploadProgress}%`}</Typography>
                                                </Box>
                                            </Box>:
                                        <LinearProgress variant='determinate'  color='inherit' value={uploadProgress} />
                                    }
                                </Grid>
                                <Grid item xs={12} onDrop={this.fileDropProcessor} sx={{padding: '3em 0em 3em 0em', backgroundColor: '#eaeaea57',textAlign:'center', marginTop:'1em'}}>
                                    <Typography variant='h7'>
                                        {selectedFiles.length == 1 ?
                                                `${selectedFiles.length} file selected...`:
                                            selectedFiles.length > 1 ?
                                                `${selectedFiles.length} files selected...`:
                                            'Drop your files here'   
                                        }
                                    </Typography>
                                </Grid>
                                
                                <Grid item xs={12} sx={{marginTop: '0.5em'}}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell component='th'>Name</TableCell>
                                                    <TableCell component='th'>Size</TableCell>
                                                    <TableCell component='th'>Action</TableCell>
                                                </TableRow>    
                                            </TableHead>  
                                            <TableBody>
                                                {selectedFiles.length <= 0 && (
                                                    <TableRow>
                                                        <TableCell>No files selected</TableCell>
                                                    </TableRow>
                                                )}
                                                {selectedFiles.map((file, index) => (
                                                    <TableRow key={index}> 
                                                        <TableCell sx={{borderBottom: 'none !important', maxWidth: '200px !important', minWidth: '200px !important'}}>{file.name}</TableCell>
                                                        <TableCell  sx={{borderBottom: 'none !important'}}>{Number(file.size/1024/1024).toFixed(2) + ' MB'}</TableCell>
                                                        <TableCell  sx={{borderBottom: 'none !important'}}>
                                                            <Tooltip title='Remove'>
                                                                <RemoveCircle  onClick={() => this.removeSelectedFile(index)} sx={{cursor: 'pointer'}}  color='error' />
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))} 
                                            </TableBody>  
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>                        
                        </AccordionDetails>
                    </Accordion>
                </Container>

                <Container style={{marginTop: '1em'}}>
                    <ThemeProvider theme={this.getMuiTheme()}>
                   
                        <MUIDataTable
                            title='Document List'
                            // title='Project list'
                            data={this.state?.documents}
                            columns={documentColumns}
                            options={options}
                        />
                    </ThemeProvider>
                </Container>
                
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <Container style={{marginTop: '1em'}}>                
                    <ProjectDescription />
                </Container>
                <Container style={{marginTop: '1em'}}>                  
                    <ThemeProvider theme={this.getMuiTheme()}>                                                   
                        <MUIDataTable
                                
                                title='Concept List'
                                data={conceptData}
                                columns={conceptColumns}
                                options={conceptOptions}
                        />
                    </ThemeProvider>
            </Container> 
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                
                
                <Container style={{marginTop: '1em'}}>                
                    <ProjectDescription />
                </Container>
                <Container style={{marginTop: '1em'}}>
                    <Checkbox
                        id='includecheck'
                        checked={searchType}
                        onChange={(e) => this.setState({searchType: e.target.checked})}
                    />
                    <label style={{cursor: 'pointer'}} htmlFor='includecheck'>Include all search keyword in same sentence</label> 
                </Container>
                <Container>
                    <Grid style={{marginBottom: '1em'}}>    
                            <TextField 
                                label="Search word 1"
                                id="outlined-size-small"
                                defaultValue="Small"
                                size="small"
                                value={searchText}
                                onChange={(e) => this.setState({searchText: e.target.value})}
                                style={{width: '28%'}} 
                            />
                            <TextField 
                                label="Search word 2"
                                id="outlined-size-small"
                                size="small"
                                value={searchText2}
                                style={{marginLeft: '1em' , width: '28%'}} 
                                onChange={(e) => this.setState({searchText2: e.target.value})}
                            />
                            <TextField 
                                label="Search word 3"
                                id="outlined-size-small"
                                size="small"
                                value={searchText3}
                                style={{marginLeft: '1em', width: '28%'}} 
                                onChange={(e) => this.setState({searchText3: e.target.value})}
                            />

                            <Button style={{marginLeft: '1em', textTransform:'capitalize'}}  onClick={() => this.searchTextData()} variant="contained"   disabled={this.state.isSearchLoading}>Search   {this.state.isSearchLoading && <CircularProgress size={20} color="inherit" />}
                            </Button>
                    </Grid>
                    
                    <ThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                            title='Search Text'
                            data={this.state?.searchTextData}
                            columns={searchTextColumns}
                            options={searchTextOptions}
                        />
                    </ThemeProvider>
                    
                </Container>
            </TabPanel>
           </Grid>

            <Modal open={addModalOpen } onClose={this.closeAddModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Add To concept
                        </Typography><br />
                        <Box id="modal-modal-description" >
                        <Typography color={'error'}>
                                {`${selectedRows.length} sentence${(selectedRows.length > 1) ? 's':''} will be added to the concept.`}
                        </Typography><br />
                            <TextField value={conceptName} onChange={(e) => this.setState({conceptName: e.target.value})} fullWidth id="outlined-basic" label="New concept name" variant="outlined" />
                        
                            
                            <>
                                <br /><br />
                                
                                <TextField select fullWidth label='Select extsting concepts' displayEmpty
                                    
                                    SelectProps={{
                                        MenuProps: {sx: { maxHeight: '280px'}},
                                        multiple: true,
                                        value: this.state.selectedConcepts,
                                        renderValue: (selected) => selected.map((x) => {
                                            let conceptName;
                                            conceptData.map(concept => {
                                                if(concept.id == x){
                                                    conceptName = concept.conceptName;
                                                }
                                            });
                                            return conceptName
                                        }).join(', '),
                                        onChange: (e) => this.setState({selectedConcepts:  e.target.value})
                                  }}
                                >
                                        
                                        {conceptData?.map(concept => (
                                        <MenuItem key={concept.id} value={concept.id}>
                                            <Checkbox checked={this.state.selectedConcepts.includes(concept.id)}/>
                                            {concept.conceptName}
                                        </MenuItem>
                                    ))}
                                </TextField> 
                            </>
                           
                            <br /><br />
                            <Button variant="contained" onClick={this.addConcept} >Save</Button>&nbsp;
                            <Button variant="outlined" onClick={this.closeAddModal} color='warning'>Close</Button>
                        </Box>

                    </Box>
                </Modal>
                <br/><br/>                
            </>
        )
    }
}


export default ProjectDetail;