import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import AuthenticationService from '../authentication/AuthenticationService';
import * as applicationConstants from '../utils/applicationConstants';
//NEW UI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
//NEW UI
import { Box, Button } from '@mui/material';

    const Header = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    let loc_array = window.location.href.split("/");
    let tabValue = loc_array[loc_array.length - 1];
    let isLoggedIn = AuthenticationService.isUserLoggedIn();
      
    const pages = ['users']//PR Intertext Registration
    function loggedout(){
        if(AuthenticationService.getEmail()){
                  
            AuthenticationService.logout();
                    window.location.href='/logout'
            
        }else{
            window.location.href='/logout'
        }
       
      }
     //NEW UI
      

      const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
    //NEW UI
     
    
    return(
        
        // <div>
        //     <header>
             
        //         <nav className="navbar navbar-expand-lg navbar-light bg-primary" style={{padding : "0px 0px"}}>
        //             <ul className="nav navbar-nav d-md-down">
        //                 <li className="nav-item">
        //                 <span className="nav-link">
        //                     <h4  className="text-light1 ml-2">Inter Text Portal</h4>
        //                     {/* <img src={process.env.PUBLIC_URL + '/watermark.png'} alt="Logo" className="img-avatar" /> */}
        //                 </span>
        //                 </li>
                       
                       
        //                 {/* {isLoggedIn && window.location.pathname !='/' &&
                        
        //                 <li className="nav-item mt-1 ml-2">
        //                     <a className="nav-link text-light1" style={{cursor: "pointer"}} href="/Project">Project Management</a>  
        //                 </li>
        //                 } */}
                       

        //             </ul>

        //             <ul className="navbar-nav ml-auto">
        //                     {
        //                         isLoggedIn && window.location.pathname !='/' && window.location.pathname !='/logout' &&
        //                         <li className="nav-item dropdown" dropdown="" placement="bottom right">
        //                             <a aria-haspopup="true" className="nav-link" data-toggle="dropdown" dropdowntoggle="" href="#" role="button" aria-expanded="false">
        //                                   <h5 className="float-left mt-2 text-light1">Welcome, {AuthenticationService.getEmail()}</h5>
        //                                 <img className="img-avatar" src={process.env.PUBLIC_URL + "/avtar.png"} />
        //                             </a>
        //                             <div aria-labelledby="simple-dropdown" className="dropdown-menu" 
        //                             style={{left: "auto", right: "0px", position: "absolute"}}>
        //                                <span className="dropdown-item" style={{cursor: "pointer"}} onClick={loggedout}>
        //                                     <i className="icon-lock"></i> Logout </span>
        //                             </div>
        //                         </li>
        //                     }
                      
                            
                            
        //             </ul>
        //         </nav>
        //     </header>
        // </div>
        //NEW UI
<AppBar position="static">
<Toolbar>
  {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>  Commented for PR Intertext Registration*/}
  <Typography variant="h6" component="div" > {/* PR Intertext Registration*/}
    INTER TEXT PORTAL
  </Typography>
  {/* PR Intertext Registration- START*/}
  <Box sx={{ flexGrow: 1 }}>
    <Button sx={{ml:'1em',textTransform: 'none', color: '#fff' }} onClick={() => window.location.href="/Project"}>
    {'Project'}
    </Button> 
    {AuthenticationService.getLoggedInUserRole().includes('ADMIN') && (
      <Button sx={{ color: '#fff' ,textTransform: 'none' }} onClick={() => window.location.href="/users"}>
      {'User Management'}
      </Button> 
    )}
  </Box>
  {/* PR Intertext Registration - END*/}
  {isLoggedIn && window.location.pathname !='/' && window.location.pathname !='/logout'  && (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
           
         
        <Typography variant="body2">Hi, {AuthenticationService.getEmail()}</Typography>   
        <AccountCircle />
        
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={loggedout}>Logout</MenuItem>
      </Menu>
    </div>
  )}
</Toolbar>
</AppBar>
//NEW UI  
        )
}
export default Header