
import { Component } from "react";
import axios from 'axios';
import {API_URL} from '../../utils/applicationConstants'

class ProjectService extends Component{
    signUp(data){
        return axios.post(`${API_URL}/userProfile/signup`, data)
    }
}


export default new ProjectService();