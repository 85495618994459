import moment from 'moment'

// export const API_URL = 'http://localhost:8080/InterTextPortal';
export const API_URL = process.env.REACT_APP_BASE_URL;

//Test URL
// export const API_URL = 'https://testintertext.conscisys.com/InterTextPortal';

//PROD URL
//export const API_URL = 'https://intertext.conscisys.com/InterTextPortal';


export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const USER_EMAIL_SESSION_ATTRIBUTE = 'email';
export const TOKEN_SESSION_ATTRIBUTE_NAME = 'token'
export const USER_ROLE_SESSION_ATTRIBUTE_NAME = 'role';
export const SUPPORTED_FILES = ['pdf'];
export const COPYRIGHT_TEXT = 'Copyright © '+ moment(new Date()).format('YYYY')+' Inter Text Portal';