import {toast} from 'react-toastify';  
import 'react-toastify/dist/ReactToastify.css';  
  
toast.configure() 
 
// Success Toaster
export const successToaster =  (message) => {
        toast.success(message) 
}

// Failure Toaster
export const failureToaster =  (message) => {
        toast.error(message) 
}
