/*
 *CPJRS 07/26/2022 PR Intertext Project : Provide feature for Registration & Sign-in 
 */
 import React from 'react'

 import 'bootstrap/dist/css/bootstrap.css';
 import { Card, Grid, Button, TextField, Typography, Alert, Modal } from '@mui/material'
 import { Box, styled } from '@mui/system'
 import { useState } from 'react';
 import ReCAPTCHA from 'react-google-recaptcha';
 import isValidString from '../utils/applicationUtils'
 import SignUpService from '../services/signup/SignUpService';
 import { failureToaster } from '../utils/Toaster';
 
 function SignUp() {
 
   const [validationDetails, setValidationDetails] = useState({
     phoneNumber: { inValid: false, helperText: '' },
     email: { inValid: false, helperText: '' },
     password: { inValid: false, helperText: '' },
     confirmPassword: { inValid: false, helperText: '' },
     isFormValid: true,
     validationMessage: '',
   });
   
   const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
   const [phoneNumber, setPhoneNumber] = useState('');
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [captcha, setCaptcha] = useState(null);
   const [successModalOpen, setSuccessModalOpen] = useState(false);
   const [headerMessage, setHeaderMessage] = useState('');
   const [bodyMessage, setBodyMessage] = useState('');
 
   const modalStyle = {
     position: 'absolute',
     top: '40%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 500,
     backgroundColor: '#f8f8f8',
     margin: 'auto',
     border: '1px solid #888',
     borderRradius: '10px',
     boxShadow: 24,
     p: 4,
 }
 
   function signUp(e) {
     e.preventDefault();
 
     if(validateForm()){
       const signUpData = {
         firstName: firstName, lastName: lastName, phoneNumber: phoneNumber, email: email, password: password, captcha: captcha
       }
       SignUpService.signUp(signUpData)
                     .then(response => { 
                       if(!response.data.hasError){
                         setSuccessModalOpen(true);
                         setHeaderMessage("Registration Success");
                         setBodyMessage(response.data.message);       
                       }else{
                          setSuccessModalOpen(true);
                          setHeaderMessage("Registration Failed");
                          setBodyMessage(response.data.message);
                       }
                     })
                     .catch(e => {
                       let response = e.response.data;
                       if(response.status == 400 && response.message.startsWith('Validation failed')){
 
                         setValidationDetails(previous => ({
                           ...previous,  isFormValid: true, validationMessage: ''
                         }));
 
                         response.errors.map(error => {
                           if(error.code == '')
                           switch(error.field){
                             
                             case 'captcha':
                               setValidationDetails(previous => ({
                                 ...previous,  isFormValid: false, validationMessage: previous.isFormValid ? error.code : previous.validationMessage
                               }));
                             break;
                             default:
                               setValidationDetails(previous => ({
                                 ...previous,  isFormValid: false, validationMessage: 'Please enter valid input in all the fields.'
                               }));
                             break;
                           }
                         })
                       }
                     });
     }
   }
 
   function validateForm() {
     let isValid = true;
     isValid =  validateRequiredField('email', email) ? isValid : false;
     isValid =  validateRequiredField('phoneNumber', phoneNumber) ? isValid : false;
     
     isValid = validateConfirmPassword() ? isValid  : false;
     
     
     setValidationDetails(previous => ({
       ...previous, isFormValid: isValid, validationMessage: isValid ? '' : 'Please enter valid input in all the fields.'
     }));
 
     
     if(isValid && !isValidString(captcha)){
       setValidationDetails(previous => ({
         ...previous, isFormValid: false, validationMessage: 'You must complete captcha.'
       }));
       isValid = false;
     }
     
     return isValid;
   }
 
   function validateRequiredField(field,value){
     if (!isValidString(value)) {
       setValidationDetails(previous => ({
         ...previous, [field]: { inValid: true, helperText: 'Field is required.' }
       }));
       return false;
     }else{
       setValidationDetails(previous => ({
         ...previous, [field]: { inValid: false, helperText: '' }
       }));
       return true;
     }
   }
   function validateConfirmPassword(){
     if(validateRequiredField('password',password) && validateRequiredField('confirmPassword', confirmPassword)){
 
         if( confirmPassword.localeCompare(password) != 0){
           setValidationDetails(previous => ({
             ...previous, confirmPassword: { inValid: true, helperText: 'Passwords do not match.' }
           }));
           return false;
         }else{
           setValidationDetails(previous => ({
             ...previous, confirmPassword: { inValid: false, helperText: '' }
           }));
           return true;
         }
     }
     return false;
   }
   
   
   const btnstyle = { margin: '8px 0' }
 
   const FlexBox = styled(Box)(() => ({
     display: 'flex',
     alignItems: 'center',
   }))
   const JustifyBox = styled(FlexBox)(() => ({
     justifyContent: 'center',
   }))
 
   const IMG = styled('img')(() => ({
     width: '100%',
   }))
 
   return (
     <Box style={{
       backgroundColor: '#1A2038',
       paddingTop: '2em',
       paddingBottom: '2em'
 
     }}>
       <Card className="card" style={{
         maxWidth: 800,
         borderRadius: 12,
         margin: '0px auto',
         padding: '1em'
       }}>
         <Grid container>
           <Grid item lg={5} md={5} sm={5} xs={12}>
             <JustifyBox p={3} sx={{ width: '100%', margin: '0px auto' }}>
               <img
                 src={process.env.PUBLIC_URL + "/logo.png"}
                 alt=""
                 style={{ width: '100%' }}
               />
             </JustifyBox>
           </Grid>
           <Grid item lg={7} md={7} sm={7} xs={12}>
             <form method="post" onSubmit={signUp}>
 
               <Box style={{
                 height: '100%',
                 padding: '32px',
                 position: 'relative',
                 background: 'rgba(0, 0, 0, 0.01)',
               }}>
                 <Typography variant="h4" gutterBottom component="div">
                   Sign up
                 </Typography>
 
                 <TextField
                   sx={{ width: '100%' }}
                   variant="outlined"
                   size="small"
                   label="First Name"
                   type="text"
                   value={firstName}
                   onChange={(e) => setFirstName(e.target.value)}
                 />
                 <TextField
                   sx={{ mt: 2, width: '100%' }}
                   variant="outlined"
                   size="small"
                   label="Last Name"
                   type="text"
                   value={lastName}
                   onChange={(e) => setLastName(e.target.value)}
                 />
                 <TextField
                   error={validationDetails.phoneNumber.inValid}
                   helperText={validationDetails.phoneNumber.helperText}
                   sx={{ mt: 2, width: '100%' }}
                   variant="outlined"
                   size="small"
                   label="Phone Number"
                   type="text"
                   value={phoneNumber}
                   onChange={(e) => setPhoneNumber(e.target.value)}
                 />
                 <TextField
                   error={validationDetails.email.inValid}
                   helperText={validationDetails.email.helperText}
                   sx={{ mt: 2, width: '100%' }}
                   variant="outlined"
                   size="small"
                   label="E-mail"
                   type="email"
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
 
                 />
                 <TextField
                   error={validationDetails.password.inValid}
                   helperText={validationDetails.password.helperText}
                   sx={{ mt: 2, width: '100%' }}
                   label="Password"
                   variant="outlined"
                   size="small"
                   type="password"
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
                 />
 
                 <TextField
                   error={validationDetails.confirmPassword.inValid}
                   helperText={validationDetails.confirmPassword.helperText}
                   sx={{ mt: 2, width: '100%' }}
                   label="Confirm Password"
                   variant="outlined"
                   size="small"
                   type="password"
                   value={confirmPassword}
                   onChange={(e) => setConfirmPassword(e.target.value)}
                 />
                 <ReCAPTCHA
                   style={{ marginTop: '10px' }}
                   sitekey={process.env.REACT_APP_SITE_KEY}
                   onChange={setCaptcha}
                 />
                 {
                   !validationDetails.isFormValid &&
                   <Alert variant="outlined" sx={{ mt: 2 }} severity="error">{validationDetails.validationMessage}</Alert>
                 }
                 <FlexBox mt={2} flexWrap="wrap">
                   <Box position="relative">
                     <Button 
                       variant="contained"
                       type="submit"
                       sx={{ backgroundColor: '#263474' }}
                     >
                       Sign up
                     </Button>
                   </Box>
                 </FlexBox>
                 <a href='/'>Click here to Sign in If you already have an account.</a>
               </Box>
             </form>
             
           </Grid>
         </Grid>
       </Card>
 
     <Modal open={successModalOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
     <center>
     <Box sx={modalStyle}>
         <Typography id="modal-modal-title" variant="h6" color="green" >
             {headerMessage}
         </Typography><br />
         <Box id="modal-modal-description" >
             <Typography id="modal-modal-title" variant="p">
                 {bodyMessage}
             </Typography><br /><br />
            <Button variant="outlined" sx={{ borderRadius: '20px', textTransform:'capitalize', ':hover':{color:'#fff',backgroundColor: 'success.main'}}} onClick={() => window.location.href="/"}  color='success' >Okay</Button>&nbsp;
         </Box>
     </Box>
     </center>
 </Modal>  
 
     </Box>
   );     
 }
 
 export default SignUp