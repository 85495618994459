import React, { Component } from 'react';
import AuthenticationService from '../authentication/AuthenticationService'
import 'bootstrap/dist/css/bootstrap.css';
import  './projectdataTable.css';
import ProjectService from '../services/project/ProjectService';
import { failureToaster, successToaster } from '../utils/Toaster';
import $ from 'jquery';
import MUIDataTable from 'mui-datatables';
import { Box, Button, Container, Modal, TextField, Tooltip, Typography,Checkbox,MenuItem } from '@mui/material';
import { Edit, DeleteForeverRounded, AddCircleOutlineOutlined , ContentCopy } from '@mui/icons-material';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';

class Project extends Component {
    constructor(props) {
        super(props)

     this.state = {
        projectName:'',
        data: [],
        id: '',
        name: '',
        description: '',
        addModalOpen: false,
        updateModalOpen: false,
        deleteModalOpen: false,
        deleteId: '',
        //PR copy Clone project -start
        projectData: [],
        selectedProjects: [],
        selectedProjectIds: [],
      usedProjects: [],
         //PR copy Clone project -end
    } 
    this.handleChange = this.handleChange.bind(this)
    this.searchClick = this.searchClick.bind(this)
    this.openAddModal = this.openAddModal.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
    this.rowSelectfunction = this.rowSelectfunction.bind(this);
    this.fetchProjectData = this.fetchProjectData.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
   }

   componentDidMount(){
    this.fetchProjectData('');
   }
   
  // PR copy Clone project - start
   setSelectedProjects(rows){
       
    let selectedProjectIds = new Array(rows.length);
    for(let i=0; i < rows.length; i++){
        selectedProjectIds[i] = rows[i].id;
    }
    this.setState({
        selectedConceptIds: selectedProjectIds
    });
  }
   // PR copy Clone project - end

   fetchProjectData(projectName){
        ProjectService.getAllProject(projectName)
        .then(result => {
            this.setState({projectData: result.data})
            this.setState({data: result.data})
        })
        .catch(error => { 
            if(error.response.status == 403){

                AuthenticationService.handleTokenExpired();
                return;
            }
            failureToaster(error.message)
        });
   }
    searchClick(){
        if(this.state.projectName.length >= 3)
            this.fetchProjectData(this.state.projectName);
        else{
            this.fetchProjectData('');
        }
    }

    
    rowSelectfunction(row) 
    {
        window.location.href = `/Project/details?projectId=${row.id}`
    }
   
    handleChange(event) {
  
        this.setState(
            {
                projectName : event.target.value
            }
        )
    }

 
    
    openUpdateModal(projectId){
        ProjectService.getProjectByProjectId(projectId)
        .then(response => {
            this.setState({
                id: response.data.id,
                description: response.data.description,
                name: response.data.name,
                usedProjects: response.data.usedProjects //PR copy Clone project
            })  
            this.setState({updateModalOpen: true})

        })
        .catch(error => { 
            if(error.response.status == 403){
                
                AuthenticationService.handleTokenExpired();
                return;
            }
            failureToaster(error.message)
        });

    }
    openAddModal(){
        this.setState({addModalOpen: true});
    }
    closeAddModal(){
        this.setState({
            addModalOpen: false,
            updateModalOpen: false,
            name: '',
            description: '',
            id: '',
            selectedProjects : [], //PR copy Clone project
            usedProjects : [] //PR copy Clone project
        });    
    }
    openDeleteModal(projectId,deleteText){
        
        this.setState({
            deleteModalOpen: true,
            deleteId: projectId,
            deleteText:deleteText
        });
    }
    
    closeDeleteModal(){
        this.setState({
            deleteModalOpen: false,
            deleteId: '',
            deleteText: ''
        });    
    }
    deleteProject(){

        const id = toast.loading("Please wait...")
        ProjectService.deleteProject(this.state.deleteId)
        .then(response => { 
            
            toast.update(id, { render: "Project deleted successfully.", type: "success", autoClose: 3000, isLoading: false });                       

            this.setState({
                deleteModalOpen: false,
                deleteId: '',
                deleteText: ''
            }); 
            this.fetchProjectData('');
        })
        .catch(error => { 
            if(error.response.status == 403){
                
                AuthenticationService.handleTokenExpired();
                return;
            } 
            toast.update(id, { render: error.message, type: "error", autoClose: 3000, isLoading: false });
                                 
        });            
    }

    saveClicked(){

        if(this.handleValidation()){
            
        const id = toast.loading("Please wait...")
            const project = {
                name: this.state.name,
                description: this.state.description,
                selectedProjectIds: this.state.selectedProjects  //PR copy Clone project        
            };    
            if(this.state.updateModalOpen){
                project.id = this.state.id;
            }
            
            
            ProjectService.addProject(project)
                            .then(response => {
                                
                                if(response.data.message == 'Success'){
                                    toast.update(id, { render: "Project saved successfully.", type: "success", autoClose: 3000, isLoading: false });
                                    this.setState({
                                        id: '',
                                        name: '',
                                        description: '',
                                    });
                                    this.closeAddModal();
                                    this.fetchProjectData('');
                                }

                            })
                            .catch(error => { 
                                if(error.response.status == 403){
                                    
                                    AuthenticationService.handleTokenExpired();
                                    return;
                                }
                                toast.update(id, { render: error.message, type: "error", autoClose: 3000, isLoading: false });
                                   
                            });
                
        }
    }
    handleValidation(){
        let projectname = this.state.name;
        if(null == projectname || '' == projectname){
            failureToaster("Project Name is required...");
            return false;
        }

        return true;
    }

    getMuiTheme = () => createTheme({
            components: {
                MUIDataTable: {
                styleOverrides: {
                    root: {
                    backgroundColor: 'red',
                    },
                    paper: {
                    boxShadow: 'none',
                    },
                },
                },
                MuiTableCell: {
                styleOverrides: {
                    head: {
                    backgroundColor: '#eaeaea5c !important',
                    color:  '#1a58b5 !important',
                    fontSize: '1.1em !important'
                    },
                },
                },
            },
    });
    render() {

    
    let { data,  updateModalOpen, addModalOpen, name, description, deleteModalOpen ,deleteText, projectData,usedProjects} = this.state;
    const modalStyle = {
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    borderRradius: '10px',
                    boxShadow: 24,
                    p: 4,
    }

    const options = {
            customToolbar: () => {

                return(
                    <Button onClick={this.openAddModal} variant="contained" style={{textTransform: 'capitalize'}}>
                     <AddCircleOutlineOutlined  color="inherit"  style={{cursor: 'pointer'}}  /> &nbsp;    
                     Add project
                    </Button>
                );
            },
            filterType: 'textField',
            selectableRows: false,
            download: false,
            print: false,
            viewColumns: false,
            rowsPerPage: 15,
            onCellClick: (colData, cellMeta) => {
                if(cellMeta.colIndex != 3){
                    window.location.href = `/Project/details?projectId=${data[cellMeta.rowIndex].id}`;  
                }
            },  

        };
    
    const columns = [
        {
            name: 'name',
            label: "Project Name",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <a style={{color: '#4615b2'}} href={`/Project/details?projectId=${data[tableMeta.rowIndex].id}`}>{value}</a>
                },
                setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                    style: { 
                        minWidth: "150px" }
                })
            }
        },
        {
            name: 'description',
            label: 'Description',
            options: {
                filter: false,
                sort: false,
                
            }
        },
        {
            name: 'createdDate',
            label: 'Created Date',
            options: {
                filter: true,
                sort: false,
                setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                    style: { 
                        minWidth: "140px"
                     }
                })
            }
        },
        {   
            name: 'id',
            label: 'Actions',
            options: {
                filter: false,
                sort: false,
                setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                    style: { 
                        minWidth: "100px" }
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                  
                    const projectName = this.state.data[tableMeta.rowIndex].name;
                    return (
                        <>
                            <Tooltip title='Edit Project'>
                                <Edit color="primary" onClick={() => this.openUpdateModal(value)}  style={{cursor: 'pointer'}} />
                            </Tooltip>
                            <Tooltip title="Delete Project">
                                <DeleteForeverRounded color="error" onClick={() => this.openDeleteModal(value,projectName)} style={{cursor: 'pointer'}}  />
                            </Tooltip>
                        </>)
                }
            }
        }
    ];

        return(
            <>
                <Container style={{marginTop: '1em'}}>
                    <ThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                            title='Project List'
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                </Container>

                <Modal open={addModalOpen || updateModalOpen} onClose={this.closeAddModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h5" component="h2">
                            Add Project
                        </Typography><br />
                        <Box id="modal-modal-description" >
                            <TextField value={name} onChange={(e) => this.setState({name: e.target.value})} fullWidth id="outlined-basic" label="Project name" variant="outlined" />
                            <br /><br />
                            <TextField value={description} onChange={(e) => this.setState({description: e.target.value})} fullWidth id="outlined-basic" label="Project Description" variant="outlined" />
                            <br /><br />
                            {/*PR- Copy clone project -start  */}
                            <TextField select fullWidth label='Select existing projects' displayEmpty
                                    
                                    SelectProps={{
                                        MenuProps: {sx: { maxHeight: '280px'}},
                                        multiple: true,
                                        value: this.state.selectedProjects,
                                        renderValue: (selected) => selected.map((x) => {
                                            let name;
                                            projectData.map(project => {
                                                if(project.id == x){
                                                    name = project.name;
                                                }
                                            });
                                            return name
                                        }).join(', '),
                                        onChange: (e) => this.setState({selectedProjects:  e.target.value})
                                  }}
                                >
                                {projectData?.filter((project) => project.id != this.state.id).map((project) => (
                                    updateModalOpen ? (
                                      (this.state.usedProjects.indexOf(project.id.toString()) !== -1) ?
                                        <MenuItem key={project.id} value={project.id} disabled>
                                            <Checkbox checked disabled />
                                            {project.name}
                                        </MenuItem>
                                       :
                                        <MenuItem key={project.id} value={project.id}>
                                            <Checkbox checked={this.state.selectedProjects.includes(project.id)} />
                                            {project.name}
                                        </MenuItem>
                                        ) 
                                        :
                                        <MenuItem key={project.id} value={project.id}>
                                        <Checkbox checked={this.state.selectedProjects.includes(project.id)}/>
                                        {project.name}
                                        </MenuItem>
                                ))}
                                </TextField> 
                                
                            <br /><br />
                            {/*PR- Copy clone project -end  */}
                            <Button variant="contained" style={{ textTransform:'capitalize'}} onClick={this.saveClicked} >Save</Button>&nbsp;
                            <Button variant="outlined"  style={{ textTransform:'capitalize'}} onClick={this.closeAddModal} color='warning'>Close</Button>
                        </Box>

                    </Box>
                </Modal>

                <Modal open={deleteModalOpen} onClose={this.closeDeleteModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                    <center>
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" >
                            Confirm
                        </Typography><br />
                        <Box id="modal-modal-description" >
                            <Typography id="modal-modal-title" variant="p">
                                Are you sure you want to delete the <b>{deleteText}</b> project?
                            </Typography><br /><br />
                            <Button variant="outlined" sx={{ borderRadius: '20px', textTransform:'capitalize', ':hover':{color:'#fff',backgroundColor: 'primary.main'}}} onClick={this.deleteProject} >Yes</Button>&nbsp;
                            <Button variant="outlined" sx={{borderRadius: '20px',  textTransform:'capitalize', ':hover':{color:'#fff',backgroundColor: 'warning.main'}}} onClick={this.closeDeleteModal} color='warning'>No</Button>
                        </Box>

                    </Box>
                    </center>
                </Modal>
                
           <br/><br/>
            </>  
        )
    }
}

export default Project