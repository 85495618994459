import { Component } from 'react';
import * as applicationConstants from '../utils/applicationConstants';
import axios from 'axios';

class AuthenticationService extends Component {
    
   /* executeLogOut(email){
        return axios.post(`${applicationConstants.API_URL}/userProfile/logout`,{
            email
        })
    }*/
    executeOAuthAuthenticationService(email, password) {
        return axios.post(`${applicationConstants.API_URL}/userProfile/login`, {
            email,
            password
        })
    }

    executeForgotPassword(email) {
        return axios.post(`${applicationConstants.API_URL}/userProfile/forgotPassword`, {
            email
        })
    }

    executeGetEmail(email) {
        return axios.get(`${applicationConstants.API_URL}/userProfile/checkEmail/${email}`
        )
    }

    executeGetLoggedInUserCount(){
        return axios.get(`${applicationConstants.API_URL}/userProfile/loggedInUserCount`)
    }

    executeResetPassword(passwordKey,password) {
        return axios.post(`${applicationConstants.API_URL}/userProfile/resetPassword`, 
         {
            passwordKey,
            password
        }
        )
    }
    
    executeFindUserByPasswordKey(passwordKey){
        return axios.get(`${applicationConstants.API_URL}/userProfile/checkPasswordKey/${passwordKey}`)
    }

    executeRemoveUserService(id){
        let token = this.getSecurityToken();
        return axios.delete(`${applicationConstants.API_URL}/userProfile/s/usr/${id}`, 
        {
            headers: {
                'Authorization': token
            },
        }
        ) 
    }

    getUserList(){
        let token = this.getSecurityToken();
        return axios.get(`${applicationConstants.API_URL}/userProfile/s/usr/getUserList`,
        {
            headers: {
                'Authorization': token
            },
        }  
        )
    }

    updateResetPassword(email,password) {
        let token = this.getSecurityToken();
        return axios.post(`${applicationConstants.API_URL}/userProfile/s/usr/updateResetPassword`, 
         {
            email,
            password,
        },
        {
            headers: {
                'Authorization': token
            }
        }
        )
    }


    getJWTTokenService(roomId) {
        let email = this.getLoggedInUserName();
        let token = this.getSecurityToken();
        return axios.post(`${applicationConstants.API_URL}/userProfile/s/usr/getGWTToken`, 
         {
            email,
            roomId
        }, {
            headers: {
                'Authorization': token
            },
        }
        )
    }



    getEmail(){    
        let user = localStorage.getItem(applicationConstants.USER_EMAIL_SESSION_ATTRIBUTE )
        if (user === null) return ''
        return user
    }
	
    createOAuthToken(token) {
        return 'Bearer ' + token
    }


    logout() {
    
        localStorage.removeItem(applicationConstants.USER_EMAIL_SESSION_ATTRIBUTE);
        localStorage.removeItem(applicationConstants.TOKEN_SESSION_ATTRIBUTE_NAME);
    }

    isUserLoggedIn() {
        let user = localStorage.getItem(applicationConstants.USER_EMAIL_SESSION_ATTRIBUTE)
        if (user === null) return false
        return true
    }

    handleTokenExpired(){
        this.logout();
        window.location.href='/?message=Session expired please login again!';
    }

    getSecurityToken() {
        let token = localStorage.getItem(applicationConstants.TOKEN_SESSION_ATTRIBUTE_NAME)
        if (token === null) return ''
        return token
    }

    setupAxiosInterceptors(token) {
        axios.interceptors.request.use(
            (config) => {
                if (this.isUserLoggedIn()) {
                    config.headers.authorization = token
                }
                return config
            }
        )
    }
    //PR Intertext Registration -START
    getLoggedInUserRole(){    
        let role = localStorage.getItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME )
        if (role === null) return ''
        return role
    }
    //PR Intertext Registration -END
}

export default new AuthenticationService()