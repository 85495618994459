import React, { Component } from 'react';
import AuthenticationService from '../authentication/AuthenticationService'
import 'bootstrap/dist/css/bootstrap.css';
import { failureToaster } from '../utils/Toaster';
import {
    Card,
    Grid,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,TextField, Typography
} from '@mui/material'
import { Box, styled, useTheme } from '@mui/system'
import * as applicationConstants from '../utils/applicationConstants';
import { toast } from 'react-toastify';

class Login extends Component {
    constructor(props) {
      
      
        super(props)

        this.state = {
            email: '',
            password: '',
            hasLoginFailed: false,
            isLoginDisable: false,
            buttonValue: "Login",
            emailValidationMessaage: '',
            passwordValidationMessage: '',
            checked: false,


            roleId: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.loginClicked = this.loginClicked.bind(this)
        this.handleCheckBoxChange =  this.handleCheckBoxChange.bind(this);
    }
    componentDidMount() {
        const message = (new URLSearchParams(window.location.search)).get('message');
        if(null != message && undefined != message && '' != message){
            failureToaster(message);
            
        }
        if((null != localStorage.getItem("userEmail") && '' != localStorage.getItem("userEmail")  )
        && (null != localStorage.getItem("userPwd") && '' != localStorage.getItem("userPwd"))){
            this.setState({ checked: true, email: localStorage.getItem("userEmail"),
            password: localStorage.getItem("userPwd") });
        }
        else{
            this.setState({ checked: false, email: '',
             password: ''});
        }
    }    
    handleChange(event) {
        this.setState({ passwordValidationMessage: '' })
        this.setState({ hasLoginFailed: false })

        if (event.target.name === 'email') {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (!pattern.test(event.target.value)) {
                this.setState({ emailValidationMessaage: 'Please enter valid email address.' })
                //this.setState({ isLoginDisable: true})
            } else {
                this.setState({ emailValidationMessaage: '' })
                //this.setState({ isLoginDisable: false})
            }
        }
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

    loginClicked() {

        this.setState({ emailValidationMessaage: '' })
        this.setState({ passwordValidationMessage: '' })

        if (!this.state.email || this.state.email === '') {
            this.setState({ emailValidationMessaage: 'Please Enter Email' })
        } else if (!this.state.password || this.state.password === '') {
            this.setState({ passwordValidationMessage: 'Please Enter Password' })
        } else {
            this.setState({ isLoginDisable: true })
            this.setState({ buttonValue: 'Please Wait...' })

            const id = toast.loading("Please wait...")
            AuthenticationService
                .executeOAuthAuthenticationService(this.state.email, this.state.password)
                .then((response) => {
                    if (response.data.accessToken !== undefined) {

                        localStorage.setItem(applicationConstants.TOKEN_SESSION_ATTRIBUTE_NAME, response.data.accessToken)
                        localStorage.setItem(applicationConstants.USER_EMAIL_SESSION_ATTRIBUTE, response.data.email)
                        localStorage.setItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME, response.data.roles);//PR Intertext Registration.
                        
                        if (null != response.data.accessToken) {
                            toast.update(id, { render: "Login success.", type: "success", autoClose: 1000, isLoading: false });
                           window.location.href = `/Project`;
                        } else {
                            this.setState({ showSuccessMessage: false })
                            this.setState({ hasLoginFailed: true })
                            this.setState({ isLoginDisable: false })
                            this.setState({ buttonValue: 'Login' })
                        }

                    } else {

                        this.setState({ showSuccessMessage: false })
                        this.setState({ hasLoginFailed: true })
                        this.setState({ isLoginDisable: false })
                        this.setState({ buttonValue: 'Login' })
                    }

                }).catch((error) => {
                    if(error.message == 'Network Error'){
                        
                        toast.update(id, { render: "Service unavailable, Try after sometime.", type: "error", autoClose: 3000, isLoading: false });
                        this.setState({ showSuccessMessage: false })
                        this.setState({ hasLoginFailed: true })
                        this.setState({ isLoginDisable: false })
                        this.setState({ buttonValue: 'Login' })

                        return;
                    }
                        
                    if(error.response.status == 400){
                        toast.update(id, { render: error.response.data.error, type: "error", autoClose: 3000, isLoading: false });
                        this.setState({ showSuccessMessage: false })
                        this.setState({ hasLoginFailed: true })
                        this.setState({ isLoginDisable: false })
                        this.setState({ buttonValue: 'Login' })
                        return;
                    }
                       //PR Intertext Registration - START
                        if(error.response.status == 404){
                        toast.update(id, { render: 'User does not exists. Please Register as new User.', type: "error", autoClose: 3000, isLoading: false });
                        this.setState({ showSuccessMessage: false })
                        this.setState({ hasLoginFailed: true })
                        this.setState({ isLoginDisable: false })
                        this.setState({ buttonValue: 'Login' })
                        return;
                    }
                        //PR Intertext Registration - END
                    if (error.response.status === 500) {
                        this.setState({ showSuccessMessage: false })
                        this.setState({ hasLoginFailed: true })
                        this.setState({ isLoginDisable: false })
                        this.setState({ buttonValue: 'Login' })
                    }
                    if (error.response.status === 401) {
                        window.location.href = "/logout";
                    }
                })
        }
    }

    handleCheckBoxChange(event){
        this.setState({ checked: event.target.checked });
        
        if(event.target.checked){
            localStorage.setItem("userEmail",this.state.email);
            localStorage.setItem("userPwd",this.state.password);
        }else{
            localStorage.setItem("userEmail","");
            localStorage.setItem("userPwd","");
        }
       

    }
    render() {
       
       // const card={ maxWidth: 800,borderRadius: 12,margin: '4rem'}
       const{checked}=this.state;
        const btnstyle={margin:'8px 0'}

        const FlexBox = styled(Box)(() => ({
            display: 'flex',
            alignItems: 'center',
        }))
        const JustifyBox = styled(FlexBox)(() => ({
            justifyContent: 'center',
        }))
        
        const IMG = styled('img')(() => ({
            width: '100%',
        }))
        // if(AuthenticationService.isUserLoggedIn()) {
        //     if(localStorage.getItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME)===1){
        //         window.location.href = '/admindashboard'
        //     }else{
        //         window.location.href = '/welcome'
        //     }
        //     // /* <Redirect to='/welcome' /> */
        //     // window.location.href = '/welcome'
        // }
        return (
            // <div>
            // <main className="main d-flex align-items-center">
            //     <div className="container mt-5">
            //         <div className="row">
            //             <div className="col-md-6 mx-auto">
            //             <h2 className='bg-primary text-light' style={{borderTopRightRadius: '5px',borderTopLeftRadius: '5px',color:'#18bc9c',textAlign: 'center', padding: '0.2em'}}>
            //                 Login
            //             </h2>
            //                 <div className="card-group" style={{marginTop: "3%"}}>
            //                     <div className="card">
            //                         {/* <div className="card-header bg-primary text-light">
            //                             <h3>Video Conference System</h3>
            //                         </div> */}
            //                         <div className="card-body">
            //                             {this.state.hasLoginFailed &&
            //                                 <div className="alert alert-danger" role="alert">
            //                                     Invalid email or password
            //                                 </div>
            //                             }

            //                             <div className="input-group">
            //                                 <div className="input-group-prepend">
            //                                     <span className="input-group-text"><i><BsPersonFill /></i></span>
            //                                 </div>
            //                                 <input type="email" className="form-control" 
            //                                     placeholder="Email" autoComplete="email"
            //                                     name="email"
            //                                     value={this.state.email}
            //                                     onChange={this.handleChange} />
            //                             </div>
            //                             <p style={{color: 'red'}}>{this.state.emailValidationMessaage}</p>
            //                             <div className="input-group mt-3">
            //                                 <div className="input-group-prepend">
            //                                     <span className="input-group-text"><i><AiFillLock /></i></span>
            //                                 </div>
            //                                 <input type="password" className="form-control" 
            //                                     placeholder="Password" autoComplete="current-password"
            //                                     name="password" 
            //                                     value={this.state.password}
            //                                     onChange={this.handleChange}
            //                                     onKeyPress={(event) => {
            //                                         var code = event.keyCode || event.which;
            //                                         if(code === 13) {
            //                                             document.getElementById('btnLogin').click();
            //                                         } 
            //                                     }}
            //                                     />
            //                             </div>
            //                             <p style={{color: 'red'}}>{this.state.passwordValidationMessage}</p>
            //                             <div className="row mt-4">
            //                                 <div className="col-6">
            //                                     <button type="submit" className="btn btn-primary px-4" 
            //                                         id="btnLogin"
            //                                         onClick={this.loginClicked}
            //                                          disabled={this.state.isLoginDisable}
            //                                     >
            //                                         {this.state.buttonValue}
            //                                     </button>
            //                                 </div>
            //                             </div>


            //                             {/* {<small>New to  Video Conference System? <a href="/registration">Sign up for free</a></small>} */}
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </main>

            // </div>  
            //NEW UI
           
            <Box style={{
                backgroundColor: '#1A2038',
                height: '100vh',
                paddingTop: '5%'
                
            }}>
            {/* <center> */}
            <Card className="card" style={{
                    maxWidth: 800,
                    borderRadius: 12,
                    margin: '0px auto',
                    padding: '1em'
                }}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                    {/* <JustifyBox p={3} height="100%"> Commented for PR Intertext Project: Provide new feature for Registration & sign-in*/}
                         <JustifyBox p={3} sx={{width:'100%', margin:'0px auto'}}> {/* PR Intertext Project: Provide new feature for Registration & sign-in*/} 
                            <img
                                src={process.env.PUBLIC_URL + "/logo.png"}
                                alt=""
                                style={{width:'100%'}} //PR Intertext Registration.
                            />
                        </JustifyBox>
                    </Grid>
                    <Grid item lg={7} md={7} sm={7} xs={12}>
                        <form method="post" onSubmit={(e) => {e.preventDefault(); this.loginClicked()}}>
                      
                        <Box style={{
                            height: '100%',
                            padding: '32px',
                            position: 'relative',
                            background: 'rgba(0, 0, 0, 0.01)',
                            }}>
                                {/*PR Intertext Project: Provide new feature for Registration & sign-in - START*/}
                                <Typography variant="h4" gutterBottom component="div">
                                    Sign in
                                </Typography>
                                {/*PR Intertext Project: Provide new feature for Registration & sign-in - END*/}
                                <TextField
                                    sx={{ mb: 3, width: '100%' }}
                                    variant="outlined"
                                    size="small"
                                    label="Email"
                                    type="email"
                                    name = "email"  value={this.state.email}
                                    onChange={this.handleChange}
                                  
                                />
                                <TextField
                                    sx={{ mb: '12px', width: '100%' }}
                                    label="Password"
                                    variant="outlined"
                                    size="small"
                                    type="password"
                                    name="password" 
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    onKeyPress={(event) => {
                                        var code = event.keyCode || event.which;
                                        if(code === 13) {
                                            document.getElementById('btnLogin').click();
                                        } 
                                    }}
                                />
                               <FormControlLabel
                                    sx={{ mb: '12px', maxWidth: 295 }}
                                    name="agreement"
                                    // onChange={[]}
                                    control={<Checkbox checked={checked} onChange={this.handleCheckBoxChange} />}
                                    label="Remember me"
                                />

                    <p style={{color: 'red'}}>{this.state.emailValidationMessaage}</p>

                                <FlexBox mb={2} flexWrap="wrap">
                                    <Box position="relative">
                                        <Button
                                            variant="contained"
                                         //   disabled={loading}
                                            type="submit"
                                            sx={{backgroundColor: '#263474'}} //  PR Intertext Project: Provide new feature for Registration & sign-in
                                            disabled={this.state.isLoginDisable}
                                        >
                                            Sign in
                                        </Button>
                                        {/* {loading && (
                                            <StyledProgress
                                                size={24}
                                                className="buttonProgress"
                                            />
                                        )} */}
                                    </Box>
                                    
                                </FlexBox>
                                <a href='signup'>Click here to Sign up If you don't have an account.</a> {/*PR Intertext Project: Provide new feature for Registration & sign-in*/}                            
                        </Box>
                        </form>
                    </Grid>
                </Grid>
            </Card>

        </Box>
            //NEW UI
        )
    }
}

export default Login