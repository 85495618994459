import { Cancel, CancelOutlined, CheckCircleOutlineOutlined, DeleteForeverRounded, Edit } from '@mui/icons-material';
import { Container,  Box, Button,  Modal, TextField, Tooltip, Typography, Chip } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import React, { useState, useEffect } from 'react'
import AuthenticationService from '../authentication/AuthenticationService';
import UserService from '../services/user/UserService';
import { failureToaster, successToaster } from '../utils/Toaster';

/*
 *CPJRS 07/26/2022 PR Intertext Project : Provide feature for Registration & Sign-in 
 */

function UserManagement() {

    const [data, setData] = useState([]);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [updateUserStatusData, setUpdateUserStatusData] = useState({userId: '', status: '', action: ''});

    useEffect(() => {
        fetchUserList();
    }, [])
    

    const fetchUserList = () => {
        UserService.findAllUsers()
                    .then(response => {
                        if(response.data.data != null && response.data.data.length > 0 ){
                            setData(response.data.data);
                        }
                    })
                    .catch(error => { 
                        if(error.response.status == 403){
                            AuthenticationService.handleTokenExpired();
                            return;
                        }
                        failureToaster(error.message)
                    });
    }

    const openConfirmModel = (id, status) =>{
        setConfirmModalOpen(true);
        let confirmAction = status == 'ACTIVE' ? 'approve' : 'reject';
        setUpdateUserStatusData({userId: id, status: status, action: confirmAction});
    }

    const updateUserStatus = () =>{
        UserService.updateUserStatus(updateUserStatusData)
            .then(response => {
                if(response.data != null){
                    if(response.data.response){
                        successToaster(response.data.message);
                    }else{
                        failureToaster(response.data.message);
                    }
                    setConfirmModalOpen(false);
                    setUpdateUserStatusData({userId: '', status: ''});
                    fetchUserList();
                }else{
                    console.log(response.data)
                    console.log('null');
                }
            })
            .catch(error => { 
                if(error.response.status == 403){
                    AuthenticationService.handleTokenExpired();
                    return;
                }
                failureToaster(error.message)
            });

    }

    const closeConfirmModal = () =>{
        setConfirmModalOpen(false);
    }
    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTable: {
            styleOverrides: {
                root: {
                backgroundColor: 'red',
                },
                paper: {
                boxShadow: 'none',
                },
            },
            },
            MuiTableCell: {
            styleOverrides: {
                head: {
                backgroundColor: '#eaeaea5c !important',
                color:  '#1a58b5 !important',
                fontSize: '1.1em !important'
                },
            },
            },
        },
    });
    const columns = [
        {
            name: 'firstName',
            label: "First Name",
            options: {
                filter: true,
                sort: false,
                setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                    style: { 
                        minWidth: "150px" }
                })
            }
        },
        {
            name: 'lastName',
            label: "Last Name",
            options: {
                filter: true,
                sort: false,
                setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                    style: { 
                        minWidth: "150px" }
                })
            }
        },
        {
            name: 'email',
            label: "Email",
            options: {
                filter: true,
                sort: false,
                setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                    style: { 
                        minWidth: "150px" }
                })
            }
        },
        {
            name: 'status',
            label: "Status",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let color = (value == 'ACTIVE') ? 'info': (value == 'REJECTED')? 'error': (value == 'PENDING')? 'warning' :'info';
                    return <Chip label={value} size="small" color={color} />
                }
            },
        },
        {   
            name: 'id',
            label: 'Actions',
            options: {
                filter: false,
                sort: false,
                setCellProps: (cellValue, rowIndex, columnIndex) => ({ 
                    style: { 
                        minWidth: "100px" }
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    let user = data.find((u) => u.id == value);
                    if(user.status == 'PENDING'){
                        return (
                        <>
                            <Tooltip title='Approve'>
                                <CheckCircleOutlineOutlined onClick={() => openConfirmModel(value, 'ACTIVE')} color="success"  style={{cursor: 'pointer'}} />
                            </Tooltip>
                            <Tooltip title="Reject">
                                <CancelOutlined color="error" onClick={() => openConfirmModel(value, 'REJECTED')} style={{cursor: 'pointer'}}  />
                            </Tooltip>
                        </>
                        )
                    }
                    return ''
                }
            }
        }
    ];
    
    const options = {
        
        filterType: 'textField',
        selectableRows: false,
        download: false,
        print: false,
        viewColumns: false,
        rowsPerPage: 15
    };
    const modalStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        borderRradius: '10px',
        boxShadow: 24,
        p: 4,
}
  return (
    <>
        <Container style={{marginTop: '1em'}}>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title='User List'
                    data={data}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>
        </Container>

        
        <Modal open={confirmModalOpen} onClose={closeConfirmModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
            <center>
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" >
                    Confirm
                </Typography><br />
                <Box id="modal-modal-description" >
                    <Typography id="modal-modal-title" variant="p">
                        Are you sure you want to {updateUserStatusData.action} the user request?
                    </Typography><br /><br />
                    <Button variant="outlined" sx={{ borderRadius: '20px', textTransform:'capitalize', ':hover':{color:'#fff',backgroundColor: 'primary.main'}}} onClick={updateUserStatus} >Yes</Button>&nbsp;
                    <Button variant="outlined" sx={{borderRadius: '20px',  textTransform:'capitalize', ':hover':{color:'#fff',backgroundColor: 'warning.main'}}} onClick={closeConfirmModal} color='warning'>No</Button>
                </Box>

            </Box>
            </center>
        </Modal>
    </>
  )
}

export default UserManagement