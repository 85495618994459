import React, { Component } from 'react';
import AuthenticationService from '../authentication/AuthenticationService'
import 'bootstrap/dist/css/bootstrap.css';


import * as applicationConstants from '../utils/applicationConstants';
//const searchParams = parse(location.search)

// export const location = () => {
//     const location = useLocation();
//   };

class Document extends Component {


      
   
    render() {

        // if(AuthenticationService.isUserLoggedIn()) {
        //     if(localStorage.getItem(applicationConstants.USER_ROLE_SESSION_ATTRIBUTE_NAME)===1){
        //         window.location.href = '/admindashboard'
        //     }else{
        //         window.location.href = '/welcome'
        //     }
        //     // /* <Redirect to='/welcome' /> */
        //     // window.location.href = '/welcome'
        // }
        return(
            <div>
            <main className="main d-flex align-items-center">
                <h1>welcome to Document Page</h1>
            </main>
           
            </div>  
        )
    }
}

export default Document;